import React, { Component } from 'react'
import Spinner from 'react-svg-spinner'
import ReactGA from 'react-ga'
import * as Scroll from 'react-scroll'

import Header from '../../components/Header'
import Navbar from '../../components/Navbar'
import Calendar from '../../components/CustomCalendar'
import ScheduleList from '../../components/ScheduleList'

import './style.scss'

import Api from '../../services/Api'

const today = new Date()

class Schedule extends Component {
	constructor(props) {
        super(props)

        this.state = {
			label: today.toLocaleString('pt-br', {month: 'long', year: 'numeric'}),
			scheduleFilterBy: today.toLocaleString('pt-br', {month: 'numeric', year: 'numeric'}),
			startDate: null,
			selectedDay: null,
			schedule: null,
			filteredSchedule: null,
			loading: false
		}

		this.setStateValues = this.setStateValues.bind(this)
		this.loadSchedule = this.loadSchedule.bind(this)
		this.filterSchedule = this.filterSchedule.bind(this)
		this.selectDay = this.selectDay.bind(this)
		this.selectMonth = this.selectMonth.bind(this)
		this.nextMonth = this.nextMonth.bind(this)
	}

	controller = new AbortController()

	componentDidMount() {
		this.loadSchedule(this.state.scheduleFilterBy)

		ReactGA.pageview(window.location.pathname + window.location.search, null, 'Agenda_Home')
	}

	componentWillUnmount() {
		this.controller.abort()
	}

	setStateValues(name, value) {
		this.setState({
			[name]: value
		})
	}

	selectDay(date) {
		const {
			selectedDay
		} = this.state

		let oldDate = new Date(selectedDay).toLocaleString('pt-br')
		let newDate = new Date(date).toLocaleString('pt-br')

		if (oldDate === newDate) {
			this.setState({selectedDay: null})
			this.filterSchedule(null)
		} else {
			this.setState({selectedDay: date})
			this.filterSchedule(date)
		}
	}

	selectMonth(date) {
		let monthYear = date.toLocaleString('pt-br', {month: 'numeric', year: 'numeric'})
		let monthYearLabel = date.toLocaleString('pt-br', {month: 'long', year: 'numeric'})

		this.setState({
			startDate: date,
			selectedDay: null,
			scheduleFilterBy: monthYear,
			label: monthYearLabel
		})

		this.loadSchedule(monthYear)
	}

	nextMonth({activeStartDate, view}) {
		this.selectMonth(activeStartDate)
	}

	async loadSchedule(monthYear) {
		this.setState({loading: true})

		let response = await Api.Schedule.getMonthSchedule(monthYear, this.controller.signal)

		if (response.success) {
			let schedule = response.data
			let filteredSchedule = null

			//if (today.getMonth()+1 === parseInt(monthYear.split('/')[0])
			//	&& today.getFullYear() === parseInt(monthYear.split('/')[1])) {
			//	filteredSchedule = Object.keys(schedule)
			//	.filter(key => parseInt(key) >= today.getDate())
			//	.reduce((obj, key) => {
			//		return {
			//			...obj,
			//			[key]: schedule[key]
			//		}
			//	}, {})
			//}

			this.setState({
				schedule: schedule,
				filteredSchedule: filteredSchedule,
				loading: false
			})

			this.scrollToToday()
		}
	}

	filterSchedule(selectedDay) {
		this.setState({loading: true})

		if (selectedDay) {
			const {
				schedule
			} = this.state

			let filteredSchedule = null

			filteredSchedule = Object.keys(schedule)
			.filter(key => parseInt(key) === selectedDay.getDate())
			.reduce((obj, key) => {
				return {
					...obj,
					[key]: schedule[key]
				}
			}, {})

			this.setState({filteredSchedule: filteredSchedule, loading: false})
		} else {
			this.setState({filteredSchedule: null, loading: false})
		}
	}

	scrollToToday() {
		let scroll   = Scroll.animateScroll,
			today    = document.getElementById('today')

		if (today) {
			scroll.scrollTo(today.offsetTop - 70)
		}
	}

	render() {
		const {
			label,
			schedule,
			filteredSchedule,
			scheduleFilterBy,
			startDate,
			selectedDay,
			loading
		} = this.state

		return (
			<div className={'schedule page'}>
				<Header title={'Agenda'} />

				<div className={'schedule__content-container'}>
					<Calendar 
						buttomLabel={label}
						activeStartDate={startDate}
						onActiveDateChange={this.nextMonth}
						onChange={this.selectDay}
						onClickMonth={this.selectMonth}
						value={selectedDay}
						tileClassName={({ date, view }) => !loading && view === 'month' && schedule[date.getDate()] && schedule[date.getDate()].length > 0 ? 'has-appointment' : ''}
					/>
					{loading ?
						<div className={'spinner-container'} key={0}>
							<Spinner
								size={'30'}
								color={'var(--green)'}
								thickness={3}
								gap={3}
								speed="fast"
							/>
						</div>
						:
						<ScheduleList
							today={today}
							schedule={filteredSchedule || schedule}
							scheduleFilterBy={scheduleFilterBy}
						/>
					}
				</div>
				<Navbar current={'schedule'} />
			</div>
		)
	}
}

export default Schedule