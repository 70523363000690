import React, { Component } from 'react'
import Router from './router'

class App extends Component {
	render() {
		// let {pathname} = this.props.location

		return (
			<div className="App">
				<Router />
			</div>
		)
	}
}

export default App