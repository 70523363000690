class Person {
    constructor(API_URL, API_KEY) {
		this.API_URL = API_URL
        this.API_KEY = API_KEY
    }

    async getByName(name, signal) {
        try {
            let request = await fetch(`${this.API_URL}/person?name=${name}`, {
                method: 'GET',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                }
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }

    async getAll(limit, page, filter, signal) {
        try {
            let request = await fetch(`${this.API_URL}/person/filter?limit=${limit}&page=${page}`, {
                method: 'POST',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                },
                body: JSON.stringify(filter)
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }

    async detail(id, signal) {
        try {
            let request = await fetch(`${this.API_URL}/person/${id}`, {
                method: 'GET',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                }
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }

    async create(object, signal) {
        try {
            let request = await fetch(`${this.API_URL}/person`, {
                method: 'POST',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                },
                body: JSON.stringify(object)
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }

    async update(id, object, signal) {
        try {
            let request = await fetch(`${this.API_URL}/person/${id}`, {
                method: 'PUT',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                },
                body: JSON.stringify(object)
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }
}

export default Person