import React, { Component } from 'react'
import Cookies from 'universal-cookie'
import Swal from 'sweetalert2'
import Spinner from 'react-svg-spinner'
import ReactGA from 'react-ga'

import Header from '../../components/Header'
import Navbar from '../../components/Navbar'

import Api from '../../services/Api'

import './style.scss'

class Options extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: false
		}

		this.logout = this.logout.bind(this)
	}

	cookies = new Cookies()

	componentDidMount() {
		ReactGA.pageview(window.location.pathname + window.location.search, null, 'Opcoes_Home')
	}

	async logout(e) {
		e.preventDefault();

		this.setState({loading: true})

		let token = this.cookies.get('token')
		let response = await Api.Auth.logout(token)

		if (response.success) {
			this.setState({loading: false})
			this.cookies.set('token', null, { path: '/' })
			window.user = null
			window.token = null
			this.props.history.push('/')
		} else {
			Swal.fire({
				title: 'Erro!',
				text: 'Ocorreu um problema ao sair. Tente novamente mais tarde.',
				type: 'error',
				confirmButtonText: 'OK'
			})
		}
	}

	render() {
		const {
			loading
		} = this.state

		const userGroups = {
			'1': '',
			'2': '',
			'3': 'Profissional'
		}

		const user = window.user

		return (
			<div className={'options page'}>
				<Header title={'Opções'} />
				<div className={'options__content-container'}>
					<div className={'options__profile-picture-wrapper'}>
						<img className={'options__profile-picture'} alt="profile pic" src="https://www.securities-services.societegenerale.com/uploads/tx_bisgbio/default-profile.png"/>
					</div>
					<p className={'options__profile-name'}>{user.name}</p>
					<p className={'options__profile-category'}>{userGroups[user.group]}</p>
					<p className={'options__profile-company'}>{user.woodwork.name}</p>
					<a href="/logout" onClick={this.logout} className={'options__logout'}>
						{loading ?
							<Spinner
								size={'13'}
								color={'var(--white)'}
								thickness={3}
								gap={3}
								speed="fast"
							/>
							:
							'Sair'
						}
					</a>
				</div>
				<Navbar current={'options'} />
			</div>
		)
	}
}

export default Options