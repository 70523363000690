import Auth from './Auth'
import Requests from './Requests'
import Schedule from './Schedule'
import Tasks from './Tasks'
import Attendances from './Attendances'
import Occurrences from './Occurrences'
import Person from './Person'

const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY

const Api = {
    Auth: new Auth(API_URL, API_KEY),
    Requests: new Requests(API_URL, API_KEY),
    Schedule: new Schedule(API_URL, API_KEY),
    Tasks: new Tasks(API_URL, API_KEY),
    Attendances: new Attendances(API_URL, API_KEY),
    Occurrences: new Occurrences(API_URL, API_KEY),
    Person: new Person(API_URL, API_KEY)
}

export default Api