import React, { Component } from 'react'

import './style.scss'

class Radio extends Component {
    constructor(props) {
        super(props)

        this.state = {
            checked: props.checked
        }

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange(event) {
		const target = event.target
		const checked = !this.state.checked
        const name = target.name
        const value = target.value

        this.setState({checked: checked})
        
        this.props.onChange(name, value)
	}

    render() {
        const {
            id,
            label,
            name,
            value,
            required,
            containerStyle,
            wrapperStyle
        } = this.props

        const {
            checked
        } = this.state

        const requiredProp = required ? {'required': true} : null

        return (
            <div
                className={'radio-content'}
                style={containerStyle}
            >
                <div
                    className={'radio-content__wrapper'}
                    style={wrapperStyle}
                >
                    <input
                        className={'radio-content__input'}
                        id={id}
                        name={name}
                        value={value}
                        type={'radio'}
                        defaultChecked={checked}
                        onChange={this.handleInputChange}
                        {...requiredProp}
                    />
                    {label &&
                        <label
                            className={'radio-content__label'}
                            htmlFor={id}
                        >
                            {label}
                        </label>
                    }
                </div>
            </div>
        )
    }
}

export default Radio