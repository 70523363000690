class Schedule {
    constructor(API_URL, API_KEY) {
		this.API_URL = API_URL
        this.API_KEY = API_KEY
    }

    async getMonthSchedule(month, signal) {
        try {
            let request = await fetch(`${this.API_URL}/schedule?month=${month}`, {
                method: 'GET',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                }
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }
}

export default Schedule