import React, { Component } from 'react'

import './style.scss'
import eye from '../../assets/images/eye.svg'
import eyeSlash from '../../assets/images/eye-slash.svg'

const passwordType = 'password'
const textType = 'text'

class Input extends Component {
    constructor(props) {
        super(props)

        this.state = {
            inputType: props.type
        }

        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    togglePasswordVisibility() {
        let newType = this.state.inputType === passwordType ? textType : passwordType
        this.setState({inputType: newType})
    }

    handleInputChange(event) {
		const target = event.target
		const value = target.value
        const name = target.name
        
        this.props.onChange(name, value)
	}

    render() {
        const {
            id,
            label,
            name,
            type,
            placeholder,
            value,
            min,
            invalid,
            invalidMessage,
            containerStyle,
            disabled,
            maxLength
        } = this.props

        const {
            inputType
        } = this.state

        const required = this.props.required ? {'required': true} : null

        return (
            <div
                className={'input-content' + (invalid === true ? ' input-content--invalid' : '') + (disabled === true ? ' input-content--disabled' : '')}
                style={containerStyle}
            >
                {label && 
                    <label
                        className={'input-content__label'}
                        htmlFor={id}
                    >
                        {label}
                    </label>
                }
                <div className={'input-content__wrapper'}>
                    <input
                        className={'input-content__input'}
                        id={id}
                        name={name}
                        type={inputType}
                        placeholder={placeholder}
                        value={value}
                        min={min}
                        onChange={this.handleInputChange}
                        disabled={disabled}
                        maxLength={maxLength}
                        {...required}
                    />
                    {type === passwordType &&
                        <span className={'input-content__toggle-password'} onClick={this.togglePasswordVisibility}>
                            { inputType === passwordType ?
                                <svg>
                                    <use xlinkHref={`${eyeSlash}#eye-slash`} />
                                </svg>
                                :
                                <svg>
                                    <use xlinkHref={`${eye}#eye`} />
                                </svg>
                            }
                        </span>
                    }
                </div>
                {invalid &&
                    <span className={'input-content__error-message'}>
                        {invalidMessage}
                    </span>
                }
            </div>
        )
    }
}

export default Input