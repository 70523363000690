import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import logo from '../../assets/images/logo-leo.svg'
//import search from '../../assets/images/search.svg'

class Header extends Component {
	render() {
		return (
			<div className={'header'}>
				<Link to={'/tasks'}>
					<svg className={'header__logo'}>
						<use xlinkHref={`${logo}#logo`} />
					</svg>
				</Link>
				{this.props.title &&
					<h1 className={'header__title'}>{this.props.title}</h1>
				}
				{/*<svg className={'header__search'}>
					<use xlinkHref={`${search}#search`} />
				</svg>*/}
			</div>
		)
	}
}

export default Header