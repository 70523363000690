import React, { Component } from 'react'
import { createBrowserHistory } from 'history'
import Swal from 'sweetalert2'
import ReactGA from 'react-ga'
import { Tabs, DefaultTabBar } from 'rmc-tabs'
import 'rmc-tabs/assets/index.css'
import * as moment from 'moment'
import 'moment/locale/pt-br'
import Spinner from 'react-svg-spinner'

import Header from '../../components/Header'
import General from '../../components/DetailsTabs/general'
import Service from '../../components/DetailsTabs/service'
import Occurrences from '../../components/DetailsTabs/occurrences'
import Budget from '../../components/BudgetsForm'
import RemoveIndication from '../../components/RemoveIndication'

import './style.scss'

import Api from '../../services/Api'

import returnArrow from '../../assets/images/return.svg'

const tabBarTextStyle = {
	fontSize: '1.3rem',
	padding: '1.8rem 0',
	fontWeight: 'bold'
}

const tabBarUnderlineStyle = {
	height: '0.3rem',
	backgroundColor: 'var(--light-green)',
	borderColor: 'var(--light-green)'
}

class Details extends Component {
	constructor(props) {
		super(props)

		this.state = {
			id: this.props.match.params.requestId,
			page: this.props.location.state && this.props.location.state.page,
			formType: this.props.location.state && this.props.location.state.formType,
			data: {},
			loading: true,
			history: createBrowserHistory(),
			height: null,
			hideTop: false
		}

		this.removeIndication = React.createRef()
		this.openRemoveIndication = this.openRemoveIndication.bind(this)
		this.changeTabsPage = this.changeTabsPage.bind(this)
		this.handleResize = this.handleResize.bind(this)
		this.completeTask = this.completeTask.bind(this)
		this.getRequest = this.getRequest.bind(this)
	}
	
	controller = new AbortController()

	handleResize() {
		let height = window.innerHeight
		let initialHeight = this.state.height

		if (initialHeight - height > initialHeight * 0.3) {
			this.setState({hideTop: true})
		} else {
			this.setState({hideTop: false})
		}

		this.setState({
			height: height
		})
	}

	async componentDidMount() {
		this.getRequest()

		ReactGA.pageview(window.location.pathname + window.location.search, null, 'Pedidos_Detalhe')
		this.handleResize()
		window.addEventListener('resize', this.handleResize)
	}

	async getRequest() {
		this.setState({loading: true})
		
		let response = await Api.Requests.detail(this.state.id, this.controller.signal)

		if (response.success && response.data) {
			this.setState({data: response.data})
			this.setState({loading: false})
		} else {
			Swal.fire({
				title: 'Erro!',
				text: 'Pedido não encontrado.',
				type: 'error',
				confirmButtonText: 'OK'
			}).then(() => {
				this.state.history.goBack()
			})
		}
	}

	componentWillUnmount() {
		this.controller.abort()
		window.removeEventListener('resize', this.handleResize)
	}

	changeTabsPage(tab, index) {
		this.setState({ page: index });
	}

	completeTask() {
		this.setState({
			page: 'service',
			formType: 'task'
		})
	}

	openRemoveIndication() {
		this.removeIndication.current.toggle()
	}

	render() {
		let {
			id,
			data,
			loading,
			history,
			page,
			formType,
			hideTop
		} = this.state

		let statusModifier = null
		let statusText = null

		switch(data.status) {
			case 'A':
				statusModifier = 'details__top__status--warning'
				statusText = 'Negociação'
				break
			case 'D':
				statusModifier = 'details__top__status--danger'
				statusText = 'Dependente'
				break
			case 'E':
				statusModifier = ''
				statusText = 'Encerrado'
				break
			case 'F':
				statusModifier = 'details__top__status--success'
				statusText = 'Fechado'
				break
			case 'N':
				statusModifier = 'details__top__status--success'
				statusText = 'Entregue'
				break
			case 'P':
				statusModifier = 'details__top__status--warning'
				statusText = 'Aberto'
				break
			case 'S':
				statusModifier = ''
				statusText = 'Descartado'
				break
			case 'I':
				statusModifier = ''
				statusText = 'Importunidade'
				break
			default:
				statusModifier = ''
		}

		return (
			<div className={'details inner_page'}>
				{!hideTop &&
					<div>
						<Header title={'Pedidos'} />

						<div className={'details__top'}>
							<span className="details__top__return" onClick={history.goBack}>
								<svg>
									<use xlinkHref={`${returnArrow}#return`} />
								</svg>
							</span>
							{!loading &&
								<div className={'details__top__info-container'}>
									<p className={'details__top__id'}>
										{`Pedido: #${ data.requestId }`}
										<span className={`details__top__status ${statusModifier}`}>
											{statusText}
										</span>
									</p>
									<p className={'details__top__date'}>{`Última atualização: ${ moment(data.updateDate).format('DD/MM/YYYY') }`}</p>
								</div>
							}
						</div>
					</div>
				}
				
				<div className={`details__tabs__container ${hideTop ? 'no-top' : ''}`}>
					<Tabs
						tabs={[
							{ key: 'general', title: 'Detalhes' },
							{ key: 'service', title: 'Atendimento' },
							{ key: 'budget', title: 'Orçamento'},
							{ key: 'occurrences', title: 'Ocorrências'}
						]}
						page={page}
						onChange={this.changeTabsPage}
						tabBarBackgroundColor={'var(--white)'}
						tabBarActiveTextColor={'var(--light-green)'}
						tabBarTextStyle={tabBarTextStyle}
						tabBarUnderlineStyle={tabBarUnderlineStyle}
						tabBarInactiveTextColor={'var(--gray-1)'}
						prerenderingSiblingsNumber={0}
						swipeable={false}
						destroyInactiveTab={true}
						renderTabBar={(props) => <DefaultTabBar {...props} page={4} />}
					>
						<div key="general" className={'details__tabs__content'}>
							{!loading ?
								<General
									request={data}
									completeTask={this.completeTask}
									removeIndication={this.openRemoveIndication}
									getRequest={this.getRequest}
								/>
								:
								<div className={'spinner-container'} key={0}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
							}
						</div>
						<div key="service" className={'details__tabs__content'}>
							{!loading ?
								<Service request={data} formType={formType} />
								:
								<div className={'spinner-container'} key={0}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
							}
						</div>
						<div key="budget" className={'details__tabs__content'}>
							{!loading ?
								<Budget id={id} request={data} />
								:
								<div className={'spinner-container'} key={0}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
							}
						</div>
						<div key="occurrences" className={'details__tabs__content'}>
							{!loading ?
								<Occurrences request={data} />
								:
								<div className={'spinner-container'} key={0}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
							}
						</div>
					</Tabs>
				</div>
				{!loading && data.type === 'I' &&
					<RemoveIndication ref={this.removeIndication} request={data} nextAction={() => history.goBack()}/>
				}
			</div>
		)
	}
}

export default Details