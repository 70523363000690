import React from 'react'

import './style.scss'
import ListItem from './ScheduleListItem'

const ScheduleList = (props) => {
    const {
        schedule,
        scheduleFilterBy,
        today
    } = props

    const isToday = (day) => {
        return today.toLocaleString('pt-br', {day: 'numeric', month: 'numeric', year: 'numeric'}) === `${day.padStart(2, '0')}/${scheduleFilterBy}`
    }

    const weekDay = (day) => {
        let monthYear = scheduleFilterBy.split('/')
        return new Date(`${monthYear[0]}/${day}/${monthYear[1]}`).toLocaleString('pt-br', {weekday: 'short'})
    }

    return (
        <div className={'schedule-content'}>
            {schedule &&
                Object.keys(schedule).map((day, index) =>
                    <div
                        id={isToday(day) ? 'today' : ''}
                        className={`schedule-content__day ${isToday(day) && 'schedule-content__day--current'}`}
                        key={index.toString()}
                    >
                        <div className={'schedule-content__day__info'}>
                            <div>{day.padStart(2, '0')}</div>
                            <p>{weekDay(day)}</p>
                        </div>
                        <div className={'schedule-content__day__appointments'}>
                            <ul>
                                {schedule[day].length > 0 ?
                                    schedule[day].map((appointment, index) =>
                                        <ListItem
                                            key={index.toString()}
                                            item={appointment}
                                            today={isToday(day)}
                                        />
                                    )
                                    :
                                    !(index + 1 === Object.keys(schedule).length) &&
                                    <ListItem
                                        key={index.toString()}
                                        item={null}
                                        today={isToday(day)}
                                    />
                                }
                                {index + 1 === Object.keys(schedule).length &&
                                    <li className={`appointment ${isToday(day) ? 'appointment--current' : ''}`}>
                                        <div className={'appointment__bill'}>
                                            <p className={'appointment__bill__title'}>
                                                Lembre-se!
                                            </p>
                                            <p className={'appointment__bill__text'}>
                                                Esta é a data do <span>vencimento</span> do seu boleto do 
                                                Leo Indica. Efetue o pagamento para continuar utilizando 
                                                nossos serviços. Obrigado!
                                            </p>
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ScheduleList