import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import Cookies from 'universal-cookie'
import ReactGA from 'react-ga'

import './style.scss'
import Api from '../../services/Api'
import Input from '../../components/CustomInput'
import Button from '../../components/CustomButton'

import logo from '../../assets/images/logo-leo.svg'

class Login extends Component {
	constructor(props) {
		super(props)

		this.state = {
			email: '',
			password: '',
			loading: false
		}

		this.setStateValues = this.setStateValues.bind(this)
		this.login = this.login.bind(this)
	}

	cookies = new Cookies()

	componentDidMount() {
		ReactGA.pageview(window.location.pathname + window.location.search, null, 'Login')
	}

	setStateValues(name, value) {
		this.setState({
			[name]: value
		})
	}

	async login(event) {
		event.preventDefault()

		if (this.state.email && this.state.password) {
			this.setState({loading: true})

			let response = await Api.Auth.login(this.state.email, this.state.password)

			this.setState({loading: false})

			if (response.success) {
				this.cookies.set('token', response.data.token, { path: '/' })

				window.user = response.data.user
				window.token = response.data.token

				this.props.history.push('/tasks')
			} else {
				Swal.fire({
					title: 'Erro!',
					text: response.errorMessage,
					type: 'error',
					confirmButtonText: 'OK'
				})
			}
		} else {
			Swal.fire({
				title: 'Erro!',
				text: 'Preencha os campos Email e Senha para continuar.',
				type: 'error',
				confirmButtonText: 'OK'
			})
		}
	}

	render() {
		const {
			email,
			password,
			loading
		} = this.state

		return (
			<div className={'login'}>
				<form className={'login__container'} onSubmit={this.login}>
					<svg className={'login__logo'}>
						<use xlinkHref={`${logo}#logo`} />
					</svg>
					<h1 className={'login__title'}>Acesse sua conta</h1>
					<Input
						id={'email'}
						label={false}
						name={'email'}
						type={'email'}
						placeholder={'Email'}
						value={email}
						onChange={this.setStateValues}
						required={true}
						//invalid={}
						//invalidMessage{}
					/>
					<Input
						id={'password'}
						label={false}
						name={'password'}
						type={'password'}
						placeholder={'Senha'}
						value={password}
						onChange={this.setStateValues}
						required={true}
						//invalid={}
						//invalidMessage={}
					/>
					<Button
						title={'Entrar'}
						type={'submit'}
						loading={loading}
						styleType={'light'}
						//disabled={true}
					/>
					{/* <Link to={'/recover'} className={'login__recover-link'}>Esqueceu sua senha?</Link> */}
				</form>
			</div>
		)
	}
}

export default Login