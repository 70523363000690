import React from 'react'
import AsyncSelect from 'react-select/async'

import './style.scss'

const CustomAsyncSelect = (props) => {
    const {
        id,
        name,
        label,
        selected,
        invalid,
        invalidMessage,
        containerStyle,
        multiple,
        placeholder,
        noOptionsMessage,
        loadOptions,
        loadingMessage
    } = props

    const handleInputChange = (event) => {
        let value = null
        if (multiple) {
            const options = event.options
            value = [...options].filter(o => o.selected).map(o => o.value)
        } else {
            value = event.value
        }
        
        props.onChange(name, value)
    }

    const required = props.required ? {'required': true} : null

    return (
        <div
            className={'async-select-content' + (invalid === true ? ' async-select-content--invalid' : '')}
            style={containerStyle}
        >
            {label && 
                <label
                    className={'async-select-content__label'}
                    htmlFor={id}
                >
                    {label}
                </label>
            }
            <div className={'async-select-content__wrapper'}>
                <AsyncSelect
                    className={'async-select-content__select'}
                    classNamePrefix={'async-select-content__select'}
                    id={id}
                    name={name}
                    value={selected}
                    onChange={handleInputChange}
                    isMulti={multiple}
                    placeholder={placeholder}
                    noOptionsMessage={noOptionsMessage}
                    loadingMessage={loadingMessage}
                    loadOptions={loadOptions}
                    cacheOptions
                    {...required}
                />
            </div>
            {invalid &&
                <span className={'async-select-content__error-message'}>
                    {invalidMessage}
                </span>
            }
        </div>
    )
}

export default CustomAsyncSelect