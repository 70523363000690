import React from 'react'

import './style.scss'

const SelectFilter = (props) => {
    const {
        id,
        name,
        label,
        options,
        selected,
    } = props

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        
        props.onChange(name, value)
    }

    return (
        <div className={'select-filter'}>
            <label
                className={'select-filter__label'}
                htmlFor={id}
            >
                {label}
            </label>
            <select
                className={'select-filter__select'}
                id={id}
                name={name}
                value={selected}
                onChange={handleInputChange}
            >
                {options.map((option, index) =>
                    <option
                        key={index.toString()}
                        value={option.value}
                    >
                        {option.title}
                    </option>
                )}
            </select>
        </div>
    )
}

export default SelectFilter