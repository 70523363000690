import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import Login from '../containers/Login'
import Recover from '../containers/Login/recover'
import Tasks from '../containers/Tasks'
import Schedule from '../containers/Schedule'
import Requests from '../containers/Requests'
import RequestsDetails from '../containers/RequestsDetails'
//import New from '../containers/New'
//import Clients from '../containers/Clients'
//import ClientsDetails from '../containers/Clients/Details'
//import ClientsNew from '../containers/Clients/New'
import Options from '../containers/Options'
import PrivateRoute from '../components/PrivateRoute'
import PublicRoute from '../components/PublicRoute'

export default () => (
	<BrowserRouter>
		<Switch>
			<PublicRoute restricted={true} component={Login} path="/" exact />
			<PublicRoute restricted={true} component={Recover} path="/recover" exact />
			<PrivateRoute component={Tasks} path="/tasks" exact />
			<PrivateRoute component={Schedule} path="/schedule" exact />
			<PrivateRoute component={Requests} path="/requests" exact />
			{/*
			<PrivateRoute component={New} path="/requests/new" exact/>
			*/}
			<PrivateRoute component={RequestsDetails} path="/requests/:requestId" />
			{/*
			<PrivateRoute component={Clients} path="/clients" exact />
			<PrivateRoute component={ClientsNew} path="/clients/new" exact/>
			<PrivateRoute component={ClientsDetails} path="/clients/:clientId" />
			*/}
			<PrivateRoute component={Options} path="/options" exact />
		</Switch>
	</BrowserRouter>
)