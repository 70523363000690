import React from 'react'
import * as moment from 'moment'
import 'moment/locale/pt-br'

import './style.scss'

const OccurrencesListItem = (props) => {
    const {
        item
    } = props

    let incident = item.statusIncident.data[0] === 1
    let typeIncident = null
    let type = null

    switch(item.type) {
        case 'M':
            type = 'Marcenaria'
            break
        default:
            type = 'Outros'
    }

    switch(item.typeIncident) {
        case 'A':
            typeIncident = 'Demora no atendimento'
            break
        case 'B':
            typeIncident = 'Demora no envio do cronograma de entrega'
            break
        case 'C':
            typeIncident = 'Demora no envio do orçamento'
            break
        case 'D':
            typeIncident = 'Móvel não foi entregue conforme solicitado'
            break
        case 'E':
            typeIncident = 'Orçamento incompatível'
            break
        case 'F':
            typeIncident = 'Demora no envio do projeto'
            break
        default:
            typeIncident = ''
    }

    return (
        <li className={`occurrence-item ${incident ? 'occurrence-item--with-incident' : ''}`}>
            <div className={'occurrence-item__content'}>
                <p className={'occurrence-item__content__incident'}>
                    <span>{'Incidente: '}</span>
                    {incident ? 'SIM' : 'NÃO'}
                    <br/>
                    {incident && <span>{'Tipo de incidente: '}</span>}
                    {typeIncident}
                </p>
                <p className={'occurrence-item__content__title'}>
                    <span>{item.text}</span>
                </p>
                <p className={'occurrence-item__content__woodwork'}>
                    <span>{`Tipo: `}</span>
                    {type}
                    <br/>
                    <span>{`Profissional: `}</span>
                    {window.user.woodwork.name}
                </p>
                <p className={'occurrence-item__content__date-time'}>
                    <span>{`Data: `}</span>
                    {moment(item.date).format('DD/MM/YYYY')}
                    {` - `}
                    <span>{`Hora: `}</span>
                    {moment(item.date).format('HH:mm:ss')}
                </p>
            </div>
        </li>
    )
}

export default OccurrencesListItem