import React, { Component } from 'react'
import * as moment from 'moment'
import 'moment/locale/pt-br'
import Spinner from 'react-svg-spinner'
import Swal from 'sweetalert2'

import Button from '../CustomButton'
import Radio from '../CustomRadio'
import Checkbox from '../CustomCheckbox'
import Input from '../CustomInput'
import Select from '../CustomSelect'
import Textarea from '../CustomTextarea'

import './style.scss'

class ServicesForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: false,
			type: this.props.type || 'task',
			registry: null,
			task: null
		}

		this.setServiceValue = this.setServiceValue.bind(this)
		this.saveService = this.saveService.bind(this)
	}

	componentDidMount() {
		this.setInitialValues()
	}

	setInitialValues() {
		let {
			openTask,
			type
		} = this.props

		if (openTask) {
			openTask.contactDate = moment(openTask.contactDate).format('YYYY-MM-DD')
			openTask.status = type === 'task' ? 'C' : 'A'
		}

		this.setState({
			registry: {
				allId: this.props.request.id,
				requestId: this.props.request.requestId,
				userId: window.user.id,
				type: 'R',
				title: 'Visita realizada',
				contactDate: moment().format('YYYY-MM-DD'),
				contactHour: moment().format('HH:mm'),
				description: '',
				contact: '',
				status: 'C'
			},
			task: openTask ||
			{
				allId: this.props.request.id,
				requestId: this.props.request.requestId,
				userId: window.user.id,
				type: 'T',
				title: 'Realizar visita',
				contactDate: '',
				contactHour: '',
				description: '',
				contact: '',
				status: 'A'
			}
		})
	}

	setServiceValue(type, property, value) {
		this.setState(prevState => ({
			[type]: {
				...prevState[type],
				[property]: value
			}
		}))
	}

	async saveService(service, event) {
		event.preventDefault()

		this.setState({loading: true})

		let serviceObject = Object.assign({}, service)
		delete serviceObject.date
		serviceObject.contactDate = `${serviceObject.contactDate.split(' ')[0]} ${serviceObject.contactHour}`
		serviceObject.updateDate = moment().format('YYYY-MM-DD HH:mm')

		if (serviceObject.id && serviceObject.status === 'C' && moment(serviceObject.contactDate) > moment()) {
			Swal.fire({
				title: 'Atenção!',
				text: 'Você deseja concluir a tarefa antes da data e hora previstas? Você pode editar a data e hora selecionando "Não"',
				type: 'question',
				confirmButtonText: 'Sim',
				showCancelButton: true,
				cancelButtonText: 'Não'
			}).then(async (result) => {
				if (result.value) {
					let saved = await this.props.save(serviceObject)
					if (saved === true) {
						this.setInitialValues()
						this.setState({loading: false})
					} else if (saved === false) {
						this.setState({loading: false})
					}
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					this.setState({loading: false})
				}
			})
		} else {
			let saved = await this.props.save(serviceObject)
			if (saved === true) {
				this.setInitialValues()
				this.setState({loading: false})
			} else if (saved === false) {
				this.setState({loading: false})
			}
		}
	}

	render() {
		let {
			loading,
			type,
			task,
			registry
		} = this.state

		let {
			openTask
		} = this.props

		let today = moment()
		let minDate = null
		let minHour = null

		if (task && task.id && task.status === 'C' && moment(openTask.contactDate) < moment(today.format('YYYY-MM-DD'))) {
			minDate = openTask.contactDate
		} else {
			minDate = today.format('YYYY-MM-DD')
		}

		if (task && task.contactDate === today.format('YYYY-MM-DD')) {
			minHour = today.format('HH:mm')
		}

		return (
			<div className={'services-form'}>
				<div className={'services-form__content'}>
					{task && registry ?
						<div>
							<div className={'services-form__type'}>
								<Radio
									id={'type_task'}
									name={'type'}
									label={'Tarefa'}
									value={'task'}
									checked={type === 'task'}
									onChange={(property, value) => this.setState({[property]: value})}
									containerStyle={{marginRight: '1.5rem'}}
								/>
								<Radio
									id={'type_registry'}
									name={'type'}
									label={'Registro'}
									value={'registry'}
									checked={type === 'registry'}
									onChange={(property, value) => this.setState({[property]: value})}
								/>
							</div>
							<form className={`services-form__form ${type === 'task' ? 'active' : ''}`} onSubmit={(event) => this.saveService(task, event)}>
								{task.id &&
									<div className={'services-form__form__block'}>
										<span className={`services-form__form__status`}>
											{'Tarefa em andamento...'}
										</span>
									</div>
								}
								<div className={'services-form__form__block'}>
									<Select
										id={'title'}
										name={'title'}
										label={'Tarefa'}
										options={[
											{title: 'Realizar visita', value: 'Realizar visita'},
											{title: 'Fazer projeto 3D', value: 'Fazer projeto 3D'},
											{title: 'Enviar orçamento', value: 'Enviar orçamento'},
											{title: 'Negociar orçamento', value: 'Negociar orçamento'}
										]}
										onChange={(property, value) => this.setServiceValue('task', property, value)}
										selected={task['title']}
										required={true}
									/>
								</div>
								<div className={'services-form__form__block'}>
									<Input
										id={'contactDate'}
										name={'contactDate'}
										label={'Data Prox. Contato'}
										type={'date'}
										onChange={(property, value) => this.setServiceValue('task', property, value)}
										value={task['contactDate'].split(' ')[0]}
										min={minDate}
										containerStyle={{marginRight: '1.5rem'}}
										required={true}
									/>
									<Input
										id={'contactHour'}
										name={'contactHour'}
										label={'Hora'}
										type={'time'}
										onChange={(property, value) => this.setServiceValue('task', property, value)}
										value={task['contactHour']}
										min={minHour}
										required={true}
									/>
								</div>
								<div className={'services-form__form__block'}>
									<Textarea
										id={'description'}
										name={'description'}
										label={'Descrição'}
										rows={3}
										maxLength={200}
										onChange={(property, value) => this.setServiceValue('task', property, value)}
										value={task['description']}
										required={task['status'] === 'C' ? true : false}
									/>
								</div>
								<div className={'services-form__form__block'}>
									<Input
										id={'contact'}
										name={'contact'}
										label={'Contato'}
										type={'text'}
										onChange={(property, value) => this.setServiceValue('task', property, value)}
										value={task['contact']}
										containerStyle={{marginRight: '1.5rem'}}
										required={true}
									/>
									<Checkbox
										id={'status'}
										name={'status'}
										label={'Tarefa concluída'}
										onChange={(property, value) => this.setServiceValue('task', property, value === true ? 'C' : 'A')}
										checked={task['status'] === 'C'}
										containerStyle={{paddingTop: '1.7rem'}}
									/>
								</div>
								<div className={'services-form__form__block'}>
									<Button
										title={'Salvar'}
										type={'submit'}
										loading={loading}
										containerStyle={{marginBottom: '2rem'}}
										//disabled={true}
									/>
								</div>
							</form>
							<form className={`services-form__form ${type === 'registry' ? 'active' : ''}`} onSubmit={(event) => this.saveService(registry, event)}>
								<div className={'services-form__form__block'}>
									<Select
										id={'title'}
										name={'title'}
										label={'Registro'}
										options={[
											{title: 'Visita realizada', value: 'Visita realizada'},
											{title: 'Projeto 3D enviado', value: 'Projeto 3D enviado'},
											{title: 'Orçamento enviado', value: 'Orçamento enviado'},
											{title: 'Acompanhamento', value: 'Acompanhamento'}
										]}
										onChange={(property, value) => this.setServiceValue('registry', property, value)}
										selected={registry['title']}
										required={true}
									/>
								</div>
								<div className={'services-form__form__block'}>
									<Input
										id={'contactDate'}
										name={'contactDate'}
										label={'Data'}
										type={'date'}
										onChange={(property, value) => this.setServiceValue('registry', property, value)}
										value={registry['contactDate'].split(' ')[0]}
										containerStyle={{marginRight: '1.5rem'}}
										required={true}
									/>
									<Input
										id={'contactHour'}
										name={'contactHour'}
										label={'Hora'}
										type={'time'}
										onChange={(property, value) => this.setServiceValue('registry', property, value)}
										value={registry['contactHour']}
										required={true}
									/>
								</div>
								<div className={'services-form__form__block'}>
									<Textarea
										id={'description'}
										name={'description'}
										label={'Descrição'}
										rows={3}
										maxLength={200}
										onChange={(property, value) => this.setServiceValue('registry', property, value)}
										value={registry['description']}
										required={true}
									/>
								</div>
								<div className={'services-form__form__block'}>
									<Button
										title={'Salvar'}
										type={'submit'}
										loading={loading}
										containerStyle={{marginBottom: '2rem'}}
										//disabled={true}
									/>
								</div>
							</form>
						</div>
						:
						<div className={'spinner-container'} key={0}>
							<Spinner
								size={'30'}
								color={'var(--green)'}
								thickness={3}
								gap={3}
								speed="fast"
							/>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default ServicesForm