class Tasks {
    constructor(API_URL, API_KEY,) {
		this.API_URL = API_URL
        this.API_KEY = API_KEY
    }

    async unrealized(date, signal) {
        try {
            let request = await fetch(`${this.API_URL}/tasks/unrealized?date=${date}`, {
                method: 'GET',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                }
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }

    async overdue(date, signal) {
        try {
            let request = await fetch(`${this.API_URL}/tasks/overdue?date=${date}`, {
                method: 'GET',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                }
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }

    async realized(date, signal) {
        try {
            let request = await fetch(`${this.API_URL}/tasks/realized?date=${date}`, {
                method: 'GET',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                }
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }
}

export default Tasks