import React, { Component } from 'react'
import Spinner from 'react-svg-spinner'
import Swal from 'sweetalert2'
import { createBrowserHistory } from 'history'
import * as moment from 'moment'
import 'moment/locale/pt-br'

import ServicesList from '../../ServicesList'
import ServicesForm from '../../ServicesForm'

import Api from '../../../services/Api'

import './style.scss'

class Service extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loadingServices: false,
			closedServices: null,
			openTask: null,
			history: createBrowserHistory()
		}

		this.saveServices = this.saveServices.bind(this)
	}

	controller = new AbortController()

	componentDidMount() {
		this.getServices()
	}

	async getServices() {
		this.setState({loadingServices: true})

		let {
			request
		} = this.props

		let filter = {}
		filter.requestId = request.requestId

		let response = await Api.Attendances.getAll(filter, this.controller.signal)

		if (response.success && response.data) {
			this.setState({
				closedServices: response.data.filter(service => service.status === 'C').sort((a,b) => new Date(b['contactDate']) - new Date(a['contactDate'])),
				openTask: response.data.filter(service => service.type === 'T' && service.status === 'A')[0],
				loadingServices: false
			})
		} else {
			Swal.fire({
				title: 'Erro!',
				text: 'Atendimentos não encontrados.',
				type: 'error',
				confirmButtonText: 'OK'
			}).then(() => {
				this.state.history.goBack()
			})
		}
	}

	async saveServices(service) {
		let response = !service.id ?
			await Api.Attendances.save(service, this.controller.signal)
			:
			await Api.Attendances.update(service, this.controller.signal)

		if (response.success) {
			Swal.fire({
				title: 'Salvo!',
				text: 'Atendimento salvo com sucesso.',
				type: 'success',
				confirmButtonText: 'OK'
			}).then(() => {
				this.getServices()
			})

			return true
		} else {
			Swal.fire({
				title: 'Erro!',
				text: 'Erro ao salvar atendimento.',
				type: 'error',
				confirmButtonText: 'OK'
			})
			
			return false
		}
	}

	render() {
		let {
			request,
			formType
		} = this.props

		let {
			closedServices,
			openTask,
			loadingServices
		} = this.state

		return (
			<div className={'service'}>
				<div className={'service__content'}>
					{!loadingServices ?
						<div>
							{(request.status !== 'E' && request.status !== 'N') &&
								<ServicesForm
									type={formType}
									openTask={openTask}
									request={request}
									save={this.saveServices}
								/>
							}
							
							<ServicesList services={closedServices} />

							{(request.indication.attendanceText || request.indication.contactDate) &&
								<div className={'service__indication'}>
									<div className={'service__indication__content'}>
										<p className={'service-item__content__title'}>
											<span>{'Indicação - Histórico'}</span>
										</p>
										{request.indication.contactDate &&
											<p className={'service__indication__date-time'}>
												<span>{`Data: `}</span>
												{moment(request.indication.contactDate).format('DD/MM/YYYY')}
												{` - `}
												<span>{`Hora: `}</span>
												{moment(request.indication.contactDate).format('HH:mm')}
											</p>
										}
										{request.indication.attendanceText &&
											<p className={'service__indication__description'}>
												{request.indication.attendanceText}
											</p>
										}
									</div>
								</div>
							}
						</div>
						:
						<div className={'spinner-container'} key={0}>
							<Spinner
								size={'30'}
								color={'var(--green)'}
								thickness={3}
								gap={3}
								speed="fast"
							/>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default Service