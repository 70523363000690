import React, { Component } from 'react'
import { Tabs } from 'rmc-tabs'
import 'rmc-tabs/assets/index.css'
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import Spinner from "react-svg-spinner"
import InfiniteScroll from 'react-infinite-scroller'
import ReactGA from 'react-ga'
//import { Link } from 'react-router-dom'

import Header from '../../components/Header'
import Navbar from '../../components/Navbar'
import SelectFilter from '../../components/SelectFilter'
import RequestsList from '../../components/RequestsList'
import Input from '../../components/CustomInput'
import Checkbox from '../../components/CustomCheckbox'
import Button from '../../components/CustomButton'

import filter from '../../assets/images/filter.svg'
//import add from '../../assets/images/add.svg'

import Api from '../../services/Api'

import './style.scss'

const tabBarTextStyle = {
	fontSize: '1.4rem',
	padding: '1.8rem 0',
	fontWeight: 'bold'
}

const tabBarUnderlineStyle = {
	height: '0.3rem',
	backgroundColor: 'var(--white)',
	borderColor: 'var(--white)'
}

const modalStyle = {
	width: '90%',
	height: '25rem',
	padding: '3.5rem 2rem',
	borderRadius: '1rem'
}

class Requests extends Component {
	constructor(props) {
        super(props)

        this.state = {
			page: props.selectedTab || 'negotiation',
			negotiationRequestsOrderBy: 'requestId',
			negotiationRequests: null,
			negotiationRequestsHasMore: true,
			loadingNegotiationRequests: false,
			closedRequestsOrderBy: 'requestId',
			closedRequests: null,
			closedRequestsHasMore: true,
			loadingClosedRequests: false,
			endedRequestsOrderBy: 'requestId',
			endedRequests: null,
			endedRequestsHasMore: true,
			loadingEndedRequests: false,
			deliveredRequestsOrderBy: 'requestId',
			deliveredRequests: null,
			deliveredRequestsHasMore: true,
			loadingDeliveredRequests: false,
			isFilterOpen: false,
			filterBy: 'requestId',
			filterTerm: '',
			filtered: false,
			checked: false,
			loading: false
		}

		this.loadNegotiationRequests = this.loadNegotiationRequests.bind(this)
		this.sortNegotiationRequests = this.sortNegotiationRequests.bind(this)
		this.loadClosedRequests = this.loadClosedRequests.bind(this)
		this.sortClosedRequests = this.sortClosedRequests.bind(this)
		this.loadEndedRequests = this.loadEndedRequests.bind(this)
		this.sortEndedRequests = this.sortEndedRequests.bind(this)
		this.loadDeliveredRequests = this.loadDeliveredRequests.bind(this)
		this.sortDeliveredRequests = this.sortDeliveredRequests.bind(this)
		this.changeTabsPage = this.changeTabsPage.bind(this)
		this.setStateValues = this.setStateValues.bind(this)
		this.toggleFilterVisibility = this.toggleFilterVisibility.bind(this)
		this.applyFilter = this.applyFilter.bind(this)
		this.clearFilter = this.clearFilter.bind(this)
		this.getFilterTerm = this.getFilterTerm.bind(this)
	}

	controller = new AbortController()

	componentDidMount() {
		ReactGA.pageview(window.location.pathname + window.location.search, null, 'Home_Pedidos_Neg')
	}

	componentWillUnmount() {
		this.controller.abort()
	}

	async loadNegotiationRequests(page, filtered=true) {
		const {
			filterBy,
			filterTerm
		} = this.state

		let filterOptions = {}
		if (filtered && (filterBy === 'type' || filterTerm)) {
			filterOptions[filterBy] = this.getFilterTerm(filterBy, filterTerm)
		}
		filterOptions.woodworkId = window.user.woodworkId
		filterOptions.status = 'A'

		let limit = page*10
		let response = await Api.Requests.getAll(limit, 1, filterOptions, this.controller.signal)

		if (response.success) {
			this.setState({
				negotiationRequests: response.data,
				negotiationRequestsHasMore: response.pages > page,
				loadingNegotiationRequests: false
			})
			this.sortNegotiationRequests(this.state.negotiationRequestsOrderBy)
		}
	}

	sortNegotiationRequests(value, track=false) {
		const {
			negotiationRequests
		} = this.state

		if (negotiationRequests) {
			if (value === 'registerDate') {
				negotiationRequests.sort((a,b) => new Date(b[value]) - new Date(a[value]))
				track && ReactGA.event({
					category: 'Pedidos',
					action: 'Clique',
					label: 'Home_Pedidos_NegVizDataE'
				})
			} else {
				negotiationRequests.sort((a,b) => a[value] - b[value])
				track && ReactGA.event({
					category: 'Pedidos',
					action: 'Clique',
					label: 'Home_Pedidos_NegVizPedido'
				})
			}

			this.setState({negotiationRequests: negotiationRequests})
		}
	}

	async loadClosedRequests(page, filtered=true) {
		const {
			filterBy,
			filterTerm
		} = this.state

		let filterOptions = {}
		if (filtered && (filterBy === 'type' || filterTerm)) {
			filterOptions[filterBy] = this.getFilterTerm(filterBy, filterTerm)
		}
		filterOptions.woodworkId = window.user.woodworkId
		filterOptions.status = 'F'

		let limit = page*10
		let response = await Api.Requests.getAll(limit, 1, filterOptions, this.controller.signal)

		if (response.success) {
			this.setState({
				closedRequests: response.data,
				closedRequestsHasMore: response.pages > page,
				loadingClosedRequests: false
			})
			this.sortClosedRequests(this.state.closedRequestsOrderBy)
		}
	}

	sortClosedRequests(value, track=false) {
		const {
			closedRequests
		} = this.state

		if (value === 'deliveryDate') {
			closedRequests.sort((a,b) => new Date(a[value]) - new Date(b[value]))
			track && ReactGA.event({
				category: 'Pedidos',
				action: 'Clique',
				label: 'Home_Pedidos_FecVizDataE'
			})
		} else {
			closedRequests.sort((a,b) => a[value] - b[value])
			track && ReactGA.event({
				category: 'Pedidos',
				action: 'Clique',
				label: 'Home_Pedidos_FecVizPedido'
			})
		}

		this.setState({closedRequests: closedRequests})
	}

	async loadEndedRequests(page, filtered=true) {
		const {
			filterBy,
			filterTerm
		} = this.state

		let filterOptions = {}
		if (filtered && (filterBy === 'type' || filterTerm)) {
			filterOptions[filterBy] = this.getFilterTerm(filterBy, filterTerm)
		}
		filterOptions.woodworkId = window.user.woodworkId
		filterOptions.status = 'E'

		let limit = page*10
		let response = await Api.Requests.getAll(limit, 1, filterOptions, this.controller.signal)

		if (response.success) {
			this.setState({
				endedRequests: response.data,
				endedRequestsHasMore: response.pages > page,
				loadingEndedRequests: false
			})
			this.sortEndedRequests(this.state.endedRequestsOrderBy)
		}
	}

	sortEndedRequests(value, track=false) {
		const {
			endedRequests
		} = this.state

		if (value === 'deliveryDate') {
			endedRequests.sort((a,b) => new Date(a[value]) - new Date(b[value]))
			track && ReactGA.event({
				category: 'Pedidos',
				action: 'Clique',
				label: 'Home_Pedidos_FecVizDataE'
			})
		} else {
			endedRequests.sort((a,b) => a[value] - b[value])
			track && ReactGA.event({
				category: 'Pedidos',
				action: 'Clique',
				label: 'Home_Pedidos_FecVizPedido'
			})
		}

		this.setState({endedRequests: endedRequests})
	}

	async loadDeliveredRequests(page, filtered=true) {
		const {
			filterBy,
			filterTerm
		} = this.state

		let filterOptions = {}
		if (filtered && (filterBy === 'type' || filterTerm)) {
			filterOptions[filterBy] = this.getFilterTerm(filterBy, filterTerm)
		}
		filterOptions.woodworkId = window.user.woodworkId
		filterOptions.status = 'N'

		let limit = page*10
		let response = await Api.Requests.getAll(limit, 1, filterOptions, this.controller.signal)

		if (response.success) {
			this.setState({
				deliveredRequests: response.data,
				deliveredRequestsHasMore: response.pages > page,
				loadingDeliveredRequests: false
			})
			this.sortDeliveredRequests(this.state.deliveredRequestsOrderBy)
		}
	}

	sortDeliveredRequests(value, track=false) {
		const {
			deliveredRequests
		} = this.state

		if (value === 'deliveryDate') {
			deliveredRequests.sort((a,b) => new Date(a[value]) - new Date(b[value]))
			track && ReactGA.event({
				category: 'Pedidos',
				action: 'Clique',
				label: 'Home_Pedidos_FecVizDataE'
			})
		} else {
			deliveredRequests.sort((a,b) => a[value] - b[value])
			track && ReactGA.event({
				category: 'Pedidos',
				action: 'Clique',
				label: 'Home_Pedidos_FecVizPedido'
			})
		}

		this.setState({deliveredRequests: deliveredRequests})
	}

	changeTabsPage(tab, index) {
		if (index === 0) {
			ReactGA.event({
				category: 'Pedidos',
				action: 'Clique',
				label: 'Home_Pedidos_Neg'
			})
		} else {
			ReactGA.event({
				category: 'Pedidos',
				action: 'Clique',
				label: 'Home_Pedidos_Fechados'
			})
		}
		this.setState({ page: index });
	}

	setStateValues(name, value) {
		this.setState({
			[name]: value
		})
	}

	toggleFilterVisibility() {
		this.setState(prevState => ({
			isFilterOpen: !prevState.isFilterOpen
		}))
	}

	applyFilter() {
		this.setState({
			loadingNegotiationRequests: true,
			loadingClosedRequests: true,
			loadingEndedRequests: true,
			loadingDeliveredRequests: true,
			loading: true
		})
		this.loadNegotiationRequests(1)
		this.loadClosedRequests(1)
		this.loadEndedRequests(1)
		this.loadDeliveredRequests(1)
		this.toggleFilterVisibility()
		this.setState({loading: false, filtered: true})
	}

	clearFilter(e) {
		e.preventDefault();

		this.setState({
			loadingNegotiationRequests: true,
			loadingClosedRequests: true,
			loadingEndedRequests: true,
			loadingDeliveredRequests: true,
			loading: true,
			filterBy: 'requestId',
			filterTerm: ''
		})
		this.loadNegotiationRequests(1, false)
		this.loadClosedRequests(1, false)
		this.loadEndedRequests(1, false)
		this.loadDeliveredRequests(1, false)
		this.setState({loading: false, filtered: false})
	}
	
	getFilterTerm(property, term) {
		if (property === 'contactDate' || property === 'registerDate') {
			return term
		} else if (property === 'type') {
			return term ? 'I' : 'M'
		} else {
			return term
		}
	}

	render() {
		const {
			page,
			negotiationRequestsOrderBy,
			negotiationRequests,
			negotiationRequestsHasMore,
			loadingNegotiationRequests,
			closedRequestsOrderBy,
			closedRequests,
			closedRequestsHasMore,
			loadingClosedRequests,
			endedRequestsOrderBy,
			endedRequests,
			endedRequestsHasMore,
			loadingEndedRequests,
			deliveredRequestsOrderBy,
			deliveredRequests,
			deliveredRequestsHasMore,
			loadingDeliveredRequests,
			isFilterOpen,
			filterBy,
			filterTerm,
			filtered,
			loading
		} = this.state

		return (
			<div className={'requests page'}>
				<Header title={'Pedidos'} />
				<Tabs
					tabs={[
						{ key: 'negotiation', title: 'Negociação' },
						{ key: 'closed', title: 'Fechados' },
						{ key: 'delivered', title: 'Entregues' },
						{ key: 'ended', title: 'Encerrados' }
					]}
					page={page}
					onChange={this.changeTabsPage}
					tabBarBackgroundColor={'var(--light-green)'}
					tabBarActiveTextColor={'var(--white)'}
					tabBarTextStyle={tabBarTextStyle}
					tabBarUnderlineStyle={tabBarUnderlineStyle}
					tabBarInactiveTextColor={'var(--green)'}
					prerenderingSiblingsNumber={0}
					swipeable={false}
				>
					<div key="negotiation" className={'requests__content-container'}>
						<div className={'requests__filters-container'}>
							<SelectFilter
								id={'negotiationRequestsOrderBy'}
								name={'negotiationRequestsOrderBy'}
								label={'Visualizar por'}
								selected={negotiationRequestsOrderBy}
								options={[
									{title: 'Pedido', value: 'requestId'},
									{title: 'Data de abertura', value: 'registerDate'}
								]}
								onChange={(name, value) => {
									this.setStateValues(name, value)
									this.sortNegotiationRequests(value, true)
								}}
							/>
							<div className={`requests__new`}>
								{/*
								<Link to={'/requests/new'}>
									<svg>
										<use xlinkHref={`${add}#add`} />
									</svg>
								</Link>
								*/}
							</div>
							<div className={`requests__toggle-filter ${filtered && 'requests__toggle-filter--filtered'}`}>
								<span onClick={this.toggleFilterVisibility}>
									<svg>
										<use xlinkHref={`${filter}#filter`} />
									</svg>
								</span>
							</div>
						</div>
						<InfiniteScroll
							pageStart={0}
							loadMore={(page) => this.loadNegotiationRequests(page)}
							hasMore={negotiationRequestsHasMore}
							loader={
								<div className={'spinner-container'} key={0}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
							}
						>
							{loadingNegotiationRequests ?
								<div className={'spinner-container'} key={0}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
								:
								<RequestsList
									requests={negotiationRequests}
								/>
							}
						</InfiniteScroll>
					</div>
					
					<div key="closed" className={'requests__content-container'}>
						<div className={'requests__filters-container'}>
							<SelectFilter
								id={'closedRequestsOrderBy'}
								name={'closedRequestsOrderBy'}
								label={'Visualizar por'}
								selected={closedRequestsOrderBy}
								options={[
									{title: 'Pedido', value: 'requestId'},
									{title: 'Data de entrega', value: 'deliveryDate'}
								]}
								onChange={(name, value) => {
									this.setStateValues(name, value)
									this.sortClosedRequests(value, true)
								}}
							/>
							<div className={`requests__new`}>
								{/*
								<Link to={'/requests/new'}>
									<svg>
										<use xlinkHref={`${add}#add`} />
									</svg>
								</Link>
								*/}
							</div>
							<div className={`requests__toggle-filter ${filtered && 'requests__toggle-filter--filtered'}`}>
								<span onClick={this.toggleFilterVisibility}>
									<svg>
										<use xlinkHref={`${filter}#filter`} />
									</svg>
								</span>
							</div>
						</div>
						<InfiniteScroll
							pageStart={0}
							loadMore={(page) => this.loadClosedRequests(page)}
							hasMore={closedRequestsHasMore}
							loader={
								<div className={'spinner-container'} key={1}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
							}
						>
							{loadingClosedRequests ?
								<div className={'spinner-container'} key={1}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
								:
								<RequestsList
									requests={closedRequests}
								/>
							}
						</InfiniteScroll>
					</div>

					<div key="delivered" className={'requests__content-container'}>
						<div className={'requests__filters-container'}>
							<SelectFilter
								id={'deliveredRequestsOrderBy'}
								name={'deliveredRequestsOrderBy'}
								label={'Visualizar por'}
								selected={deliveredRequestsOrderBy}
								options={[
									{title: 'Pedido', value: 'requestId'},
									{title: 'Data de entrega', value: 'deliveryDate'}
								]}
								onChange={(name, value) => {
									this.setStateValues(name, value)
									this.sortDeliveredRequests(value, true)
								}}
							/>
							<div className={`requests__new`}>
								{/*
								<Link to={'/requests/new'}>
									<svg>
										<use xlinkHref={`${add}#add`} />
									</svg>
								</Link>
								*/}
							</div>
							<div className={`requests__toggle-filter ${filtered && 'requests__toggle-filter--filtered'}`}>
								<span onClick={this.toggleFilterVisibility}>
									<svg>
										<use xlinkHref={`${filter}#filter`} />
									</svg>
								</span>
							</div>
						</div>
						<InfiniteScroll
							pageStart={0}
							loadMore={(page) => this.loadDeliveredRequests(page)}
							hasMore={deliveredRequestsHasMore}
							loader={
								<div className={'spinner-container'} key={1}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
							}
						>
							{loadingDeliveredRequests ?
								<div className={'spinner-container'} key={1}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
								:
								<RequestsList
									requests={deliveredRequests}
								/>
							}
						</InfiniteScroll>
					</div>

					<div key="ended" className={'requests__content-container'}>
						<div className={'requests__filters-container'}>
							<SelectFilter
								id={'endedRequestsOrderBy'}
								name={'endedRequestsOrderBy'}
								label={'Visualizar por'}
								selected={endedRequestsOrderBy}
								options={[
									{title: 'Pedido', value: 'requestId'},
									{title: 'Data de entrega', value: 'deliveryDate'}
								]}
								onChange={(name, value) => {
									this.setStateValues(name, value)
									this.sortEndedRequests(value, true)
								}}
							/>
							<div className={`requests__new`}>
								{/*
								<Link to={'/requests/new'}>
									<svg>
										<use xlinkHref={`${add}#add`} />
									</svg>
								</Link>
								*/}
							</div>
							<div className={`requests__toggle-filter ${filtered && 'requests__toggle-filter--filtered'}`}>
								<span onClick={this.toggleFilterVisibility}>
									<svg>
										<use xlinkHref={`${filter}#filter`} />
									</svg>
								</span>
							</div>
						</div>
						<InfiniteScroll
							pageStart={0}
							loadMore={(page) => this.loadEndedRequests(page)}
							hasMore={endedRequestsHasMore}
							loader={
								<div className={'spinner-container'} key={1}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
							}
						>
							{loadingEndedRequests ?
								<div className={'spinner-container'} key={1}>
									<Spinner
										size={'30'}
										color={'var(--green)'}
										thickness={3}
										gap={3}
										speed="fast"
									/>
								</div>
								:
								<RequestsList
									requests={endedRequests}
								/>
							}
						</InfiniteScroll>
					</div>
				</Tabs>
				
				<Navbar current={'requests'} />

				<Rodal
					visible={isFilterOpen}
					onClose={this.toggleFilterVisibility}
					animation={'slideUp'}
					className={'modal'}
					customStyles={modalStyle}
					customMaskStyles={{background: 'rgba(0, 0, 0, .8)'}}
				>
					<div>
						<SelectFilter
							id={'filterBy'}
							name={'filterBy'}
							label={'Filtrar por'}
							selected={filterBy}
							options={[
								{title: 'Número', value: 'requestId'},
								{title: 'Consumidor', value: 'clientName'},
								{title: 'Próximo Contato', value: 'contactDate'},
								{title: 'Indicação', value: 'type'},
								{title: 'Criado em', value: 'registerDate'}
							]}
							onChange={this.setStateValues}
						/>
						{filterBy === 'requestId' &&
							<Input
								id={'filterTerm'}
								label={false}
								name={'filterTerm'}
								type={'number'}
								placeholder={'Número do pedido'}
								value={filterTerm}
								onChange={this.setStateValues}
								required={true}
							/>
						}
						{(filterBy === 'contactDate' || filterBy === 'registerDate') &&
							<Input
								id={'filterTerm'}
								label={false}
								name={'filterTerm'}
								type={'date'}
								value={filterTerm}
								onChange={this.setStateValues}
								required={true}
							/>
						}
						{filterBy === 'type' &&
							<Checkbox
								id={'filterTerm'}
								label={'É indicação'}
								name={'filterTerm'}
								checked={false}
								onChange={this.setStateValues}
								required={true}
							/>
						}
						{filterBy === 'clientName' &&
							<Input
								id={'filterTerm'}
								label={false}
								name={'filterTerm'}
								type={'text'}
								placeholder={'Nome'}
								value={filterTerm}
								onChange={this.setStateValues}
								required={true}
							/>
						}
						<Button
							title={'Filtrar'}
							type={'submit'}
							loading={loading}
							styleType={'light'}
							onClick={this.applyFilter}
						/>
						<div className={'modal__link-container'}>
							<a href="/clear" onClick={this.clearFilter}>Limpar filtro</a>
						</div>
					</div>
				</Rodal>
			</div>
		)
	}
}

export default Requests