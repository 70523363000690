import React, { Component } from 'react'
//import * as moment from 'moment'
//import 'moment/locale/pt-br'
import Spinner from 'react-svg-spinner'
import Swal from 'sweetalert2'
import VMasker from 'vanilla-masker'


import Api from '../../services/Api'
import Button from '../CustomButton'
import Input from '../CustomInput'

import './style.scss'

class BudgetsForm extends Component {
	constructor(props) {
		super(props)

		this.indicationOpen = this.props.request.type === 'I' && this.props.request.status === 'A'
		this.budget = this.indicationOpen ? this.props.request.indication : this.props.request
		this.moneyConfig = {
			precision: 2,
			separator: ',',
			delimiter: '.',
			unit: 'R$',
			zeroCents: true
		}
		this.controller = new AbortController()
		this.state = {
			loading: false,
			budget: {
				budgetValue: this.budget.budgetValue ? VMasker.toMoney(this.budget.budgetValue, this.moneyConfig) : '',
				cloasingDate: this.budget.cloasingDate ? this.budget.cloasingDate.substring(0, 10) : '',
				closingValue: this.budget.closingValue ? VMasker.toMoney(this.budget.closingValue, this.moneyConfig) : '',
				paymentType: this.budget.paymentType || '',
				deliveryDate: this.budget.deliveryDate ? this.budget.deliveryDate.substring(0, 10) : '',
				realizedDeliveryDate: this.budget.realizedDeliveryDate ? this.budget.realizedDeliveryDate.substring(0, 10) : ''
			}
		}

		this.setBudgetValue = this.setBudgetValue.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}


	setBudgetValue(property, value) {
		this.setState(prevState => ({
			budget: {
				...prevState.budget,
				[property]: value
			}
		}))
	}

	async handleSubmit(event) {
		event.preventDefault()

		this.setState({loading: true})

		let update = {
			budgetValue: this.state.budget.budgetValue ? VMasker.toNumber(this.state.budget.budgetValue.substring(0, this.state.budget.budgetValue.length - 2)) : 0,
			cloasingDate: this.state.budget.cloasingDate || null,
			closingValue: this.state.budget.closingValue ? VMasker.toNumber(this.state.budget.closingValue.substring(0, this.state.budget.closingValue.length - 2)) : 0
		}

		if (update.budgetValue === '0') {
			Swal.fire({
				title: 'Erro!',
				text: 'O valor do orçamento precisa ser maior que zero.',
				type: 'error',
				confirmButtonText: 'OK'
			})

			this.setState({loading: false})

			return
		}

		if (update.cloasingDate && update.closingValue === '0') {
			Swal.fire({
				title: 'Erro!',
				text: 'O valor fechado precisa ser maior que zero.',
				type: 'error',
				confirmButtonText: 'OK'
			})

			this.setState({loading: false})

			return
		}

		let response

		if (this.indicationOpen) {
			response = await Api.Requests.updateIndication(this.props.id, update, this.controller.signal)
		} else {
			update = {
				...update,
				paymentType: this.state.budget.paymentType || null,
				deliveryDate: this.state.budget.deliveryDate || null,
				realizedDeliveryDate: this.state.budget.realizedDeliveryDate || null
			}

			response = await Api.Requests.update(this.props.id, update, this.controller.signal)
		}

		if (response.success) {
			//this.setState({data: response.data})
			this.setState({loading: false})

			Swal.fire({
				title: 'Salvo!',
				text: 'Orçamento salvo com sucesso.',
				type: 'success',
				confirmButtonText: 'OK'
			})
		} else {
			Swal.fire({
				title: 'Erro!',
				text: 'Não foi possível atualizar os dados.',
				type: 'error',
				confirmButtonText: 'OK'
			})
		}
	}

	render() {
		let {
			loading,
			budget
		} = this.state

		return (
			<div className={'budgets-form'}>
				<div className={'budgets-form__content'}>
					{budget ?
						<div>
							<form className={'budgets-form__form'} onSubmit={(event) => this.handleSubmit(event)}>
								<div className={'budgets-form__form__block'}>
									<Input
										id={'budgetValue'}
										name={'budgetValue'}
										label={'Valor do orçamento'}
										type={'tel'}
										onChange={(property, value) => this.setBudgetValue(property, VMasker.toMoney(value, this.moneyConfig))}
										value={budget['budgetValue']}
										disabled={this.props.request.status === 'F' || this.props.request.status === 'E' || this.props.request.status === 'N'}
										required={true}
									/>
								</div>
								<div className={'budgets-form__form__block'}>
									<Input
										id={'cloasingDate'}
										name={'cloasingDate'}
										label={'Data de fechamento'}
										type={'date'}
										onChange={(property, value) => this.setBudgetValue(property, value)}
										value={budget['cloasingDate']}
										containerStyle={{marginRight: '1.5rem'}}
										disabled={this.props.request.status === 'F' || this.props.request.status === 'E' || this.props.request.status === 'N'}
										required={(budget['closingValue'] ? VMasker.toNumber(budget['closingValue'].substring(0, budget['closingValue'].length - 2)) : '0') !== '0'}
									/>
									<Input
										id={'closingValue'}
										name={'closingValue'}
										label={'Valor fechado'}
										type={'tel'}
										onChange={(property, value) => this.setBudgetValue(property, VMasker.toMoney(value, this.moneyConfig))}
										value={budget['closingValue']}
										disabled={this.props.request.status === 'F' || this.props.request.status === 'E' || this.props.request.status === 'N'}
										required={!!budget['cloasingDate']}
									/>
								</div>
								{this.props.request.status === 'F' &&
									<div className={'budgets-form__form__block'}>
										<Input
											id={'paymentType'}
											name={'paymentType'}
											label={'Forma de pagamento'}
											type={'text'}
											onChange={(property, value) => this.setBudgetValue(property, value)}
											value={budget['paymentType']}
											//required={true}
										/>
									</div>
								}
								{this.props.request.status === 'F' &&
									<div className={'budgets-form__form__block'}>
										<Input
											id={'deliveryDate'}
											name={'deliveryDate'}
											label={'Entrega prevista'}
											type={'date'}
											onChange={(property, value) => this.setBudgetValue(property, value)}
											value={budget['deliveryDate']}
											//min={}
											containerStyle={{marginRight: '1.5rem'}}
											//required={true}
										/>
										<Input
											id={'realizedDeliveryDate'}
											name={'realizedDeliveryDate'}
											label={'Entrega real'}
											type={'date'}
											onChange={(property, value) => this.setBudgetValue(property, value)}
											value={budget['realizedDeliveryDate']}
											//min={}
											//required={true}
										/>
									</div>
								}
								{(this.props.request.status !== 'E' && this.props.request.status !== 'N') &&
									<div className={'services-form__form__block'}>
										<Button
											title={'Salvar'}
											type={'submit'}
											loading={loading}
											containerStyle={{marginBottom: '2rem'}}
											//disabled={true}
										/>
									</div>
								}
							</form>
						</div>
						:
						<div className={'spinner-container'} key={0}>
							<Spinner
								size={'30'}
								color={'var(--green)'}
								thickness={3}
								gap={3}
								speed="fast"
							/>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default BudgetsForm