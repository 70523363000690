import React, { Component } from 'react'
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import Swal from 'sweetalert2'
import * as moment from 'moment'
import 'moment/locale/pt-br'

import Button from '../../components/CustomButton'
import Select from '../../components/CustomSelect'
import Textarea from '../../components/CustomTextarea'

import Api from '../../services/Api'

const modalStyle = {
	width: '90%',
	height: '34rem',
	padding: '3.5rem 2rem',
	borderRadius: '1rem'
}

class RemoveIndication extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            loading: false,
            info: null
        }

        this.toggle = this.toggle.bind(this)
        this.setInfo = this.setInfo.bind(this)
    }

    controller = new AbortController()

    componentDidMount() {
        this.setInitialValues()
    }

    setInitialValues() {
        this.setState({
            info: {
                status: '',
                reason: ''
            }
        })
    }

    toggle() {
        this.setState(previousState => ({
            isOpen: !previousState.isOpen
        }))
    }

    setInfo(property, value) {
		this.setState(prevState => ({
			info: {
				...prevState.info,
				[property]: value
			}
		}))
    }
    
    async removeIndication(info, event) {
        event.preventDefault()
        
        let {
            request,
            nextAction
        } = this.props

        this.setState({loading: true})

        Swal.fire({
            title: 'Atenção!',
            text: 'Você tem certeza que deseja remover a indicação? Essa ação é irreversível!',
            type: 'question',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.value) {
                let response = await Api.Requests.removeIndication(request.id, info, this.controller.signal)
                
                if (response.success) {
                    Swal.fire({
                        title: 'Removido!',
                        text: 'Indicação removida com sucesso.',
                        type: 'success',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        this.setState({loading: false})
                        this.toggle()
                        this.setInitialValues()
                        nextAction()
                    })
                } else {
                    Swal.fire({
                        title: 'Erro!',
                        text: 'Erro ao remover a indicação.',
                        type: 'error',
                        confirmButtonText: 'OK'
                    })
                    this.setState({loading: false})
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.setState({loading: false})
            }
        })
	}

    render() {
        const {
            loading,
            isOpen,
            info
        } = this.state

        const {
            request
        } = this.props

        let statusOptions = [
            {title: 'Selecione o motivo', value: ''},
            {title: 'Recusado', value: 2},
            {title: 'Encerrado', value: 3}
        ]

        if (moment.utc().diff(moment.utc(request.indication.date), 'hours') > 48) {
            statusOptions = statusOptions.filter(so => so.value !== 2)
        }

        return (
            <Rodal
                visible={isOpen}
                onClose={this.toggle}
                animation={'slideUp'}
                className={'modal'}
                customStyles={modalStyle}
                customMaskStyles={{background: 'rgba(0, 0, 0, .8)'}}
            >
                <div className={'remove-indication'}>
                    {info &&
                        <form className={'remove-indication__form'} onSubmit={(event) => this.removeIndication(info, event)}>
                            <Select
                                id={'status'}
                                name={'status'}
                                label={'Motivo'}
                                options={statusOptions}
                                onChange={(property, value) => this.setInfo(property, value)}
                                selected={info['status']}
                                required={true}
                            />
                            <Textarea
                                id={'reason'}
                                name={'reason'}
                                label={'Descreva o motivo'}
                                rows={3}
                                maxLength={200}
                                onChange={(property, value) => this.setInfo(property, value)}
                                value={info['reason']}
                                required={true}
                            />
                            <Button
                                title={'Remover'}
                                type={'submit'}
                                loading={loading}
                                styleType={'light'}
                            />
                        </form>
                    }
                </div>
            </Rodal>
        )
    }
}

export default RemoveIndication