import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'
import './assets/styles/default.scss'
import App from './App'

const Leo = withRouter(props => <App {...props}/>)

ReactGA.initialize('UA-107944055-2')

ReactDOM.render(
	<Router>
		<Leo />
	</Router>,
	document.getElementById('root'),
)