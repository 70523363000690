import React, { Component } from 'react'

import './style.scss'

class Textarea extends Component {
    constructor(props) {
        super(props)

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange(event) {
		const target = event.target
		const value = target.value
        const name = target.name
        
        this.props.onChange(name, value)
	}

    render() {
        const {
            id,
            label,
            name,
            placeholder,
            value,
            rows,
            maxLength,
            invalid,
            invalidMessage,
            containerStyle
        } = this.props

        const required = this.props.required ? {'required': true} : null

        return (
            <div
                className={'textarea-content' + (invalid === true ? ' textarea-content--invalid' : '')}
                style={containerStyle}
            >
                {label &&
                    <label
                        className={'textarea-content__label'}
                        htmlFor={id}
                    >
                        {label}
                    </label>
                }
                <div className={'textarea-content__wrapper'}>
                    <textarea
                        className={'textarea-content__textarea'}
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        rows={rows}
                        maxLength={maxLength}
                        onChange={this.handleInputChange}
                        {...required}
                    ></textarea>
                </div>
                {invalid &&
                    <span className={'textarea-content__error-message'}>
                        {invalidMessage}
                    </span>
                }
            </div>
        )
    }
}

export default Textarea