import React from 'react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import * as moment from 'moment'
import 'moment/locale/pt-br'

import './style.scss'

const ScheduleListItem = (props) => {
    const {
        item,
        today
    } = props

    return (
        <li className={`appointment ${today ? 'appointment--current' : ''} ${!item ? 'appointment--empty' : ''} ${item && item.attendanceStatus === 'C' ? 'appointment--complete' : ''}`}>
            {item ?
                <Link
                    to={`/requests/${item.id}`}
                    className={'appointment__order-link'}
                    onClick={() =>
                        ReactGA.event({
                            category: 'Agenda',
                            action: 'Clique',
                            label: 'Agenda_Detalhe'
                        })
                    }
                >
                    <div className={'appointment__content-container'}>
                        <p className={'appointment__content-container__task'}>
                            <span>Tarefa: </span>
                            {item.service}
                        </p>
                        <p className={'appointment__content-container__order'}>
                            <i>
                                <span>Pedido: </span>
                                {`#${item.requestId}`}
                            </i>
                            {item.contactText &&
                                <i>
                                    <span>Contato: </span>
                                    {item.contactText}
                                </i>
                            }
                            {item.contactDate && item.contactHour &&
                                <i>
                                    <span>Hora: </span>
                                    {item.contactHour}
                                </i>
                            }
                        </p>
                        {item.attendanceStatus === 'C' &&
                            <p className={'appointment__content-container__complete-task'}>
                                {`
                                    Concluída em: 
                                    ${moment.utc(item.attendanceDate).format('DD/MM/YYYY')}
                                    às
                                    ${moment.utc(item.attendanceDate).format('HH:mm')}
                                `}
                            </p>
                        }
                    </div>
                </Link>
                :
                <div className={'appointment__content-container'}>
                    Não há compromissos.
                </div>
            }
        </li>
    )
}

export default ScheduleListItem