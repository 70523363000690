import Cookies from 'universal-cookie'
import Api from '../services/Api'

export const isLogged = async() => {
	let cookies = new Cookies()
	let token = cookies.get('token')
	let logged = false

	if(token) {
		if(window.user){
			logged = true
			window.token = token
		}else{
			let response = await Api.Auth.check(token)

			if (response.success) {
				// eslint-disable-next-line require-atomic-updates
				window.user = response.data.user
				// eslint-disable-next-line require-atomic-updates
				window.token = token

				logged = true
			}
		}
	}

	return logged
}