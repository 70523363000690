import React, { Component } from 'react'
import Spinner from "react-svg-spinner"
import * as moment from 'moment'
import 'moment/locale/pt-br'
import VMasker from 'vanilla-masker'
import ReactGA from 'react-ga'

import Button from '../../CustomButton'
import RequestsForm from '../../RequestsForm'

import './style.scss'
import profile from '../../../assets/images/profile.svg'
import envelope from '../../../assets/images/envelope.svg'
import telephone from '../../../assets/images/telephone.svg'
import place from '../../../assets/images/place.svg'
import whatsapp from '../../../assets/images/whatsapp.svg'
import calendar from '../../../assets/images/calendar.svg'
import contact from '../../../assets/images/contact.svg'
import taskDone from '../../../assets/images/task-done.svg'
import remove from '../../../assets/images/remove.svg'
import description from '../../../assets/images/description.svg'

class General extends Component {
	constructor(props) {
		super(props)

		this.state = {
			requestUrl: process.env.REACT_APP_PEDIDOS_URL,
			editing: false
		}

		this.toggleEditing = this.toggleEditing.bind(this)
	}

	toggleEditing() {
		this.setState(previousState => ({editing: !previousState.editing}))
	}

	render() {
		let {
			editing
		} = this.state

		let {
			request,
			loading,
			completeTask,
			removeIndication,
			getRequest
		} = this.props

		let types = {
			'M': 'Pedido próprio',
			'I': 'Indicação LEO'
		}

		let addressObj = {
			address: !loading && request.client.address ? request.client.address : '',
			district: !loading && request.client.district ? request.client.district : '',
			city: !loading && request.client.city ? request.client.city : '',
			state: !loading && request.client.state ? request.client.state : '',
			cep: !loading && request.client.cep ? request.client.cep : '',
		}

		let linkAddress = loading ? null : ((request.client.address && addressObj.address.replace(/ /g, '+')) || addressObj.district.replace(/ /g, '+') + '+' + addressObj.city.replace(/ /g, '+') + '+' + addressObj.state.replace(/ /g, '+') + '+' + addressObj.cep)
		let showTaskInfo = request.attendanceStatus === 'A' || (request.contactDate && moment(moment.utc(request.contactDate).format('YYYY-MM-DD HH:mm')) > moment())

		return (
			<div className={'general'}>
				<div className={'general__content'}>
					{!loading &&
						<div>
							{!editing &&
								<div>
									{showTaskInfo &&
										<div>
											<div className={'general__content__block'}>
												<h2 className={'general__title'}>{request.service}</h2>
											</div>
											<div className={'general__content__block'}>
												<dl className={'general__info general__info--inline'}>
													<dt>
														<svg>
															<use xlinkHref={`${contact}#contact`} />
														</svg>
													</dt>
													<dd>
														{request.contactText}
													</dd>
												</dl>
												<dl className={'general__info general__info--inline'}>
													<dt>
														<svg>
															<use xlinkHref={`${calendar}#calendar`} />
														</svg>
													</dt>
													<dd>
														{`${moment(request.contactDate).format('LL')} - ${request.contactHour}`}
													</dd>
												</dl>
												{request.description &&
													<dl className={'general__info general__info--inline'}>
														<dt>
															<svg>
																<use xlinkHref={`${description}#description`} />
															</svg>
														</dt>
														<dd>
															{request.description}
														</dd>
													</dl>
												}
												{request.attendanceStatus === 'C' &&
													<dl className={'general__info general__info--inline'}>
														<dt className={'general__info__complete-time'}>
															{`
																Concluída em: 
																${moment.utc(request.attendanceDate).format('DD/MM/YYYY')}
																às
																${moment.utc(request.attendanceDate).format('HH:mm')}
															`}
														</dt>
													</dl>
												}
											</div>
										</div>
									}
									{((showTaskInfo && request.attendanceStatus === 'A') || (request.type === 'I' && (request.status === 'A' || request.status === 'D'))) &&
										<div className={'general__content__block'}>
											<dl className={'general__info general__info--inline'}>
												{showTaskInfo && request.attendanceStatus === 'A' &&
													<button
														className={'general__info__button'}
														onClick={() => completeTask()}
													>
														<svg>
															<use xlinkHref={`${taskDone}#task_done`} />
														</svg>
														{'Concluir'}
													</button>
												}
												{request.type === 'I' && (request.status === 'A' || request.status === 'D') &&
													<button
														className={'general__info__button'}
														onClick={() => removeIndication()}
													>
														<svg>
															<use xlinkHref={`${remove}#remove`} />
														</svg>
														{'Remover indicação'}
													</button>
												}
											</dl>
										</div>
									}
									<div className={'general__content__block'}>
										<h3 className={'general__subtitle'}>Detalhes do Projeto</h3>
										<hr/>
										{request.form &&
										<dl className={'general__info general__info--inline'}>
											<dt>Projeto:</dt>
											<dd>{ request.form['field-project'] }</dd>
										</dl>
										}
										<dl className={'general__info general__info--inline'}>
											<dt>Tipo:</dt>
											<dd>{ types[request.type] }</dd>
										</dl>
										{request.form &&
										<dl className={'general__info general__info--inline'}>
											<dt>Desejo:</dt>
											<dd>{ request.form['field-wish'] }</dd>
										</dl>
										}
										{request.form &&
										<dl className={'general__info general__info--inline'}>
											<dt>Fatores:</dt>
											<dd>{ request.form['field-factor'] }</dd>
										</dl>
										}
										{request.form &&
										<dl className={'general__info general__info--inline'}>
											<dt>Valor:</dt>
											<dd>{ request.form['field-value'] }</dd>
										</dl>
										}
										{request.form &&
										<dl className={'general__info general__info--inline'}>
											<dt>Versão:</dt>
											<dd>{ request.form['field-version'] }</dd>
										</dl>
										}
										<dl className={'general__info'}>
											<dt>Descrição:</dt>
											<dd>{ request.message }</dd>
										</dl>
									</div>
									<div className={'general__content__block'}>
										<h3 className={'general__subtitle'}>Detalhes do Cliente</h3>
										<hr/>
										<dl className={'general__info general__info--inline'}>
											<dt>
												<svg>
													<use xlinkHref={`${profile}#profile`} />
												</svg>
											</dt>
											<dd>{ request.client.name }</dd>
										</dl>
										{request.client.email &&
											<dl className={'general__info general__info--inline'}>
												<dt>
													<svg>
														<use xlinkHref={`${envelope}#envelope`} />
													</svg>
												</dt>
												<dd>
													<a
														href={`mailto:${request.client.email}`}
														onClick={() =>
															ReactGA.event({
																category: 'Detalhe',
																action: 'Clique',
																label: 'Detalhe_Email'
															})
														}

													>
														{ request.client.email }
													</a>
												</dd>
											</dl>
										}
										{request.client.phone &&
											<dl className={'general__info general__info--inline'}>
												<dt>
													<svg>
														<use xlinkHref={`${telephone}#telephone`} />
													</svg>
												</dt>
												<dd>
													<a
														href={`tel:${request.client.phone}`}
														onClick={() =>
															ReactGA.event({
																category: 'Detalhe',
																action: 'Clique',
																label: 'Detalhe_Cel'
															})
														}
													>
														{ request.client.phone.length === 11 ? VMasker.toPattern(request.client.phone, "(99) 99999-9999") : VMasker.toPattern(request.client.phone, "(99) 9999-9999") }
													</a>
													<span>-</span>
													<a
														href={`https://api.whatsapp.com/send?phone=${'55' + request.client.phone}`}
														target="_blank"
														rel="noopener noreferrer"
														onClick={() =>
															ReactGA.event({
																category: 'Detalhe',
																action: 'Clique',
																label: 'Detalhe_Whats'
															})
														}
													>
														<svg>
															<use xlinkHref={`${whatsapp}#whatsapp`} />
														</svg>
														{'WhatsApp'}
													</a>
												</dd>
											</dl>
										}
										<dl className={'general__info general__info--inline'}>
											<dt>
												<svg>
													<use xlinkHref={`${place}#place`} />
												</svg>
											</dt>
											<dd>
												<a
													href={`http://maps.google.com/?q=${linkAddress}`}
													target="_blank"
													rel="noopener noreferrer"
													onClick={() =>
														ReactGA.event({
															category: 'Detalhe',
															action: 'Clique',
															label: 'Detalhe_Endereco'
														})
													}
												>
													{ request.client.address || request.client.district + ' - ' + request.client.city + '/' + request.client.state + ' - ' + request.client.cep }
												</a>
											</dd>
										</dl>
									</div>
									{request.type === 'M' &&
										<div className={'general__content__block'}>
											<Button
												title={'Editar Pedido'}
												type={'submit'}
												styleType={'light'}
												onClick={() => {
													this.toggleEditing()
													ReactGA.event({
														category: 'Detalhe',
														action: 'Clique',
														label: 'Detalhe_EditarPedido'
													})
												}}
											/>
										</div>
									}
								</div>
							}
							<RequestsForm
								editRequest={request}
								editing={editing}
								toggleEditing={this.toggleEditing}
								getRequest={getRequest}
							/>
						</div>
					}
				</div>

				{loading &&
					<div className={'spinner-container'} key={0}>
						<Spinner
							size={'30'}
							color={'var(--green)'}
							thickness={3}
							gap={3}
							speed="fast"
						/>
					</div>
				}
			</div>
		)
	}
}

export default General