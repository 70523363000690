class Attendances {
    constructor(API_URL, API_KEY) {
		this.API_URL = API_URL
        this.API_KEY = API_KEY
    }

    async getAll(filter, signal) {
        try {
            let request = await fetch(`${this.API_URL}/attendance/filter`, {
                method: 'POST',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                },
                body: JSON.stringify(filter)
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }

    async save(attendance, signal) {
        try {
            let request = await fetch(`${this.API_URL}/attendance`, {
                method: 'POST',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                },
                body: JSON.stringify(attendance)
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }

    async update(attendance, signal) {
        try {
            let request = await fetch(`${this.API_URL}/attendance/${attendance.id}`, {
                method: 'PUT',
                signal: signal,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': window.token
                },
                body: JSON.stringify(attendance)
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }
}

export default Attendances