import React, { Component } from 'react'
import Spinner from 'react-svg-spinner'
import Swal from 'sweetalert2'
import * as Scroll from 'react-scroll'
import { withRouter } from 'react-router-dom'

import Button from '../CustomButton'
import Checkbox from '../CustomCheckbox'
import Input from '../CustomInput'
import Select from '../CustomSelect'
import Textarea from '../CustomTextarea'
import AsyncSelect from '../CustomAsyncSelect'

import Api from '../../services/Api'

import './style.scss'

class RequestsForm extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: false,
			request: null,
			invalidClient: false
		}

		this.setRequestValue = this.setRequestValue.bind(this)
		this.saveRequest = this.saveRequest.bind(this)
		this.setInitialValues = this.setInitialValues.bind(this)
	}

	controller = new AbortController()

	componentDidMount() {
		this.setInitialValues()
	}

	setInitialValues() {
		let {
			editRequest
		} = this.props

		this.setState({
			request:editRequest ?
				editRequest.type === 'M' ? 
				{
					wish: editRequest.pro.wish || '',
					project: editRequest.pro.project || '',
					value: editRequest.pro.value || '',
					factor: editRequest.pro.factor || '',
					sourceWeb: editRequest.pro.sourceWeb || '',
					message: editRequest.pro.message || '',
					status: editRequest.pro.status || '',
					endedStatus: editRequest.pro.endedStatus || '',
					situationStatus: editRequest.pro.situationStatus || '',
					postClosureAttendanceText: editRequest.pro.postClosureAttendanceText || '',
					maintenance: (editRequest.pro.maintenance && editRequest.pro.maintenance.data[0]) || ''
				}
				:
				{
					statusNegotiation: editRequest.statusNegotiation || '',
					statusClose: editRequest.statusClose || '',
					statusEnded: editRequest.statusEnded || ''
				}
			:
			{
				clientId: '',
				wish: '',
				project: '',
				value: '',
				factor: '',
				sourceWeb: '',
				message: '',
				status: '',
				endedStatus: '',
				situationStatus: '',
				postClosureAttendanceText: '',
				maintenance: '',
				woodworkId: window.user.woodworkId,
				attendanceText: '',
				contactDate: null,
				budgetValue: null,
				cloasingDate: null,
				closingValue: null,
				paymentType: '',
				deliveryDate: null,
				realizedDeliveryDate: null,
				attendanceStatus: ''
			}
		})
	}

	setRequestValue(property, value) {
		this.setState(prevState => ({
			request: {
				...prevState.request,
				[property]: value
			}
		}))
	}

	async saveRequest(request, event) {
		event.preventDefault()

		this.setState({loading: true})
		
		let {
			editRequest,
			toggleEditing,
			getRequest,
			history
		} = this.props

		if (editRequest) {
			let response = await Api.Requests.update(editRequest.id, request, this.controller.signal)
					
			if (response.success) {
				Swal.fire({
					title: 'Pedido atualizado!',
					text: 'Pedido atualizado com sucesso.',
					type: 'success',
					confirmButtonText: 'OK'
				}).then(() => {
					this.setState({loading: false})
					this.setInitialValues()
					toggleEditing()
					getRequest()
				})
			} else {
				Swal.fire({
					title: 'Erro!',
					text: 'Erro ao atualizar pedido.',
					type: 'error',
					confirmButtonText: 'OK'
				})
				this.setState({loading: false})
			}
		} else {
			if (!request.clientId) {
				this.setState({
					invalidClient: true,
					loading: false
				})
				Scroll.animateScroll.scrollToTop()

				return
			}

			let response = await Api.Requests.create(request, this.controller.signal)

			if (response.success) {
				Swal.fire({
					title: 'Pedido criado!',
					text: 'Para editar mais detalhes do pedido, abra o mesmo a partir da listagem!',
					type: 'success',
					confirmButtonText: 'OK'
				}).then(() => {
					this.setState({loading: false})
					history.goBack()
				})
			} else {
				Swal.fire({
					title: 'Erro!',
					text: 'Erro ao criar pedido.',
					type: 'error',
					confirmButtonText: 'OK'
				})
				this.setState({loading: false})
			}
		}
	}
	  
	async loadPeople(inputValue, callback) {
		let request = await Api.Person.getByName(inputValue, this.controller.signal)

		if (request.success) {
			callback(request.data.map(p => {
				return {label: p.name, value: p.idPerson}
			}))
		}
	}

	render() {
		let {
			loading,
			request,
			invalidClient
		} = this.state

		let {
			editRequest,
			toggleEditing,
			history,
			editing
		} = this.props

		return (
			<div className={'requests-form'}>
				<div className={'requests-form__content'}>
					{request ?
						(!editRequest || (editing && editRequest.type === 'M') ?
							<form className={`requests-form__form`} onSubmit={(event) => this.saveRequest(request, event)}>
								{!editRequest && 
									<div className={'requests-form__form__block'}>
										<AsyncSelect
											id={'clientId'}
											name={'clientId'}
											label={'Cliente'}
											placeholder={'Selecione o cliente'}
											noOptionsMessage={() => 'Nenhum cliente encontrado'}
											loadingMessage={() => 'Buscando clientes...'}
											onChange={(property, value) => {
												this.setRequestValue(property, value)
												this.setState({invalidClient: false})
											}}
											loadOptions={(inputValue, callback) => this.loadPeople(inputValue, callback)}
											invalidMessage={'Você precisa selecionar um cliente!'}
											invalid={invalidClient}
										/>
									</div>
								}
								<div className={'requests-form__form__block'}>
									<Select
										id={'wish'}
										name={'wish'}
										label={'Desejo'}
										options={[
											{title: 'Selecione o desejo', value: ''},
											{title: 'Mobiliar um ambiente ou mais', value: 'Mobiliar um ambiente ou mais'},
											{title: 'Fazer um móvel simples', value: 'Fazer um móvel simples'},
											{title: 'Reformar móveis', value: 'Reformar móveis'}
										]}
										onChange={(property, value) => this.setRequestValue(property, value)}
										selected={request['wish']}
										required={true}
									/>
								</div>
								<div className={'requests-form__form__block'}>
									<Select
										id={'project'}
										name={'project'}
										label={'Projeto'}
										options={[
											{title: 'Sobre o projeto', value: ''},
											{title: 'Tenho referências e já sei o que quero', value: 'Tenho referências e já sei o que quero'},
											{title: 'Tenho projeto feito por arquiteto', value: 'Tenho projeto feito por arquiteto'},
											{title: 'Preciso de um projeto', value: 'Preciso de um projeto'}
										]}
										onChange={(property, value) => this.setRequestValue(property, value)}
										selected={request['project']}
										required={true}
									/>
								</div>
								<div className={'requests-form__form__block'}>
									<Select
										id={'value'}
										name={'value'}
										label={'Valor'}
										options={[
											{title: 'Selecione o valor', value: ''},
											{title: 'até 1.000,00', value: 'Até 1.000,00'},
											{title: 'acima de 10.000,00', value: 'acima de 10.000,00'},
											{title: 'de 1.000,00 até 10.000,00', value: 'de 1.000,00 até 10.000,00'}
										]}
										onChange={(property, value) => this.setRequestValue(property, value)}
										selected={request['value']}
										required={true}
									/>
								</div>
								<div className={'requests-form__form__block'}>
									<Select
										id={'factor'}
										name={'factor'}
										label={'Importância'}
										options={[
											{title: 'Preço', value: 'PREÇO'},
											{title: 'Rapidez', value: 'RAPIDEZ'},
											{title: 'Excelência', value: 'EXCELÊNCIA'}
										]}
										onChange={(property, value) => this.setRequestValue(property, value.length ? value.join(',') : 'NÃO PREENCHEU')}
										selected={request['factor'].split(',')}
										multiple={true}
									/>
								</div>
								<div className={'requests-form__form__block'}>
									<Select
										id={'sourceWeb'}
										name={'sourceWeb'}
										label={'Origem'}
										options={[
											{title: 'Selecione a origem', value: ''},
											{title: 'Indicação', value: 'Indicação'},
											{title: 'Facebook', value: 'Facebook'},
											{title: 'Google', value: 'Google'},
											{title: 'Direto', value: 'Direto'}
										]}
										onChange={(property, value) => this.setRequestValue(property, value)}
										selected={request['sourceWeb']}
										required={true}
									/>
								</div>
								<div className={'requests-form__form__block'}>
									<Textarea
										id={'message'}
										name={'message'}
										label={'Descrição do projeto'}
										rows={3}
										maxLength={200}
										onChange={(property, value) => this.setRequestValue(property, value)}
										value={request['message']}
									/>
								</div>
								<div className={'requests-form__form__block'}>
									<Select
										id={'status'}
										name={'status'}
										label={'Status'}
										options={[
											{title: 'Selecione o status', value: ''},
											//{title: 'Aberto', value: 'P'},
											{title: 'Dependente', value: 'D'},
											//{title: 'Descartado', value: 'S'},
											{title: 'Encerrado', value: 'E'},
											{title: 'Entregue', value: 'N'},
											{title: 'Fechado', value: 'F'},
											//{title: 'Importunidade', value: 'I'},
											{title: 'Negociação', value: 'A'},
											//{title: 'Tendência', value: 'T'}
										]}
										onChange={(property, value) => this.setRequestValue(property, value)}
										selected={request['status']}
									/>
								</div>
								<div className={'requests-form__form__block'}>
									<Select
										id={'endedStatus'}
										name={'endedStatus'}
										label={'Encerrou'}
										options={[
											{title: 'Selecione o substatus', value: ''},
											{title: 'Cancelado', value: '7'},
											{title: 'Em orçamento', value: '1'},
											{title: 'Faltou profissional', value: 'X'},
											{title: 'Fechou com outro', value: '2'},
											{title: 'Fora da área', value: '3'},
											{title: 'Fora de escopo', value: '4'},
											{title: 'Marceneiro', value: '5'},
											{title: 'Por atendimento', value: '9'},
											{title: 'Por preço', value: '6'},
											{title: 'Reforma', value: 'Y'},
											{title: 'Sem contato', value: 'Z'},
											{title: 'Sem retorno', value: '8'}
										]}
										onChange={(property, value) => this.setRequestValue(property, value)}
										selected={request['endedStatus']}
									/>
								</div>
								<div className={'services-form__form__block'}>
									<Input
										id={'situationStatus'}
										name={'situationStatus'}
										label={'ST Situação'}
										type={'text'}
										onChange={(property, value) => this.setRequestValue(property, value)}
										value={request['situationStatus']}
										maxLength={1}
									/>
								</div>
								<div className={'requests-form__form__block'}>
									<Textarea
										id={'postClosureAttendanceText'}
										name={'postClosureAttendanceText'}
										label={'Acompanhamento (pós-fechamento)'}
										rows={3}
										maxLength={200}
										onChange={(property, value) => this.setRequestValue(property, value)}
										value={request['postClosureAttendanceText']}
									/>
								</div>
								<div className={'requests-form__form__block'}>
									<Checkbox
										id={'maintenance'}
										name={'maintenance'}
										label={'Teve manutenção'}
										onChange={(property, value) => {
											this.setRequestValue(property, value === true ? 1 : 0)
										}}
										checked={request['maintenance'] === 1}
									/>
								</div>
								<div className={'requests-form__form__block'}>
									<Button
										title={'Cancelar'}
										styleType={'light'}
										containerStyle={{marginRight: '1rem'}}
										onClick={() => editRequest ? toggleEditing() : history.goBack()}
									/>
									<Button
										title={'Salvar'}
										type={'submit'}
										loading={loading}
										//disabled={true}
									/>
								</div>
							</form>
							:
							((editRequest.type === 'I' && (editRequest.status === 'F' || editRequest.status === 'E')) &&
								<form className={`requests-form__form`} onSubmit={(event) => this.saveRequest(request, event)}>
									{editRequest.status === 'F' &&
										<div className={'requests-form__form__block'}>
											<Select
												id={'statusClose'}
												name={'statusClose'}
												label={'Fechado'}
												options={[
													{title: 'Selecione o substatus', value: ''},
													{title: 'Contrato', value: 'A'},
													{title: 'Instalação', value: 'E'},
													{title: 'Produção', value: 'D'},
													{title: 'Projeto 3D', value: 'B'},
													{title: 'Sinal', value: 'C'},
													{title: 'Vistoria', value: 'F'}
												]}
												onChange={(property, value) => this.setRequestValue(property, value)}
												selected={request['statusClose']}
											/>
										</div>
									}
									{editRequest.status === 'E' &&
										<div className={'requests-form__form__block'}>
											<Select
												id={'statusEnded'}
												name={'statusEnded'}
												label={'Encerrado'}
												options={[
													{title: 'Selecione o substatus', value: ''},
													{title: 'Em orçamento', value: '1'},
													{title: 'Fechou com outro', value: '2'},
													{title: 'Fora da área', value: '3'},
													{title: 'Fora de escopo', value: '4'},
													{title: 'Marceneiro', value: '5'},
													{title: 'Por preço', value: '6'},
													{title: 'Cancelado', value: '7'},
													{title: 'Sem retorno', value: '8'},
													{title: 'Por atendimento', value: '9'},
													{title: 'Faltou profissional', value: 'X'},
													{title: 'Reforma', value: 'Y'},
													{title: 'Sem contato', value: 'Z'}
												]}
												onChange={(property, value) => this.setRequestValue(property, value)}
												selected={request['statusEnded']}
												disabled={true}
											/>
										</div>
									}
									{(((editRequest.statusClose || '') !== request.statusClose) || ((editRequest.statusEnded || '') !== request.statusEnded)) &&
										<div className={'requests-form__form__block'}>
											<Button
												title={'Cancelar'}
												type={'button'}
												styleType={'light'}
												containerStyle={{marginRight: '1rem'}}
												onClick={() => this.setInitialValues()}
											/>
											<Button
												title={'Salvar'}
												type={'submit'}
												loading={loading}
												//disabled={true}
											/>
										</div>
									}
								</form>
							)
						)
						:
						<div className={'spinner-container'} key={0}>
							<Spinner
								size={'30'}
								color={'var(--green)'}
								thickness={3}
								gap={3}
								speed="fast"
							/>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default withRouter(RequestsForm)