import React from 'react'

import './style.scss'
import ListItem from './ServicesListItem'

const ServicesList = (props) => {
    const {
        services
    } = props

    return (
        <div className={'services-list'}>
            <h3 className={'services-list__title'}>Realizados</h3>
			<hr/>
            {services && services.length > 0 ?
                <ul>
                    {services.map((service, index) =>
                        <ListItem
                            key={index.toString()}
                            item={service}
                        />
                    )}
                </ul>
                :
                <p className={'services-list__no-service-text'}>{services && services.length === 0 && 'Nenhum novo atendimento.'}</p>
            }
        </div>
    )
}

export default ServicesList