import React, { Component } from 'react'
import Spinner from 'react-svg-spinner'
import Swal from 'sweetalert2'
import { createBrowserHistory } from 'history'

//import OccurrencesForm from '../../OccurrencesForm'
import OccurrencesList from '../../OccurrencesList'

import Api from '../../../services/Api'

import './style.scss'

class Occurrences extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loadingOccurrences: false,
			occurrences: null,
			history: createBrowserHistory()
		}

		this.saveOccurrence = this.saveOccurrence.bind(this)
	}

	controller = new AbortController()

	componentDidMount() {
		this.getOccurrences()
	}

	async getOccurrences() {
		this.setState({loadingOccurrences: true})

		let {
			request
		} = this.props

		let filter = {}
		filter.requestId = request.requestId

		let response = await Api.Occurrences.getAll(filter, this.controller.signal)

		if (response.success && response.data) {
			this.setState({
				occurrences: response.data.sort((a,b) => new Date(b['date']) - new Date(a['date'])),
				loadingOccurrences: false
			})
		} else {
			Swal.fire({
				title: 'Erro!',
				text: 'Ocorrências não encontradas.',
				type: 'error',
				confirmButtonText: 'OK'
			}).then(() => {
				this.state.history.goBack()
			})
		}
	}

	async saveOccurrence(occurrence) {
		let response = await Api.Occurrences.save(occurrence, this.controller.signal)

		if (response.success) {
			Swal.fire({
				title: 'Salvo!',
				text: 'Ocorrência salva com sucesso.',
				type: 'success',
				confirmButtonText: 'OK'
			}).then(() => {
				this.getOccurrences()
			})

			return true
		} else {
			Swal.fire({
				title: 'Erro!',
				text: 'Erro ao salvar a ocorrência.',
				type: 'error',
				confirmButtonText: 'OK'
			})
			
			return false
		}
	}

	render() {
		//let {
		//	request
		//} = this.props

		let {
			occurrences,
			loadingOccurrences
		} = this.state

		return (
			<div className={'occurrences'}>
				<div className={'occurrences__content'}>
					{!loadingOccurrences ?
						<div>
							{/*<OccurrencesForm request={request} save={this.saveOccurrence}/>*/}
							<OccurrencesList occurrences={occurrences} />
						</div>
						:
						<div className={'spinner-container'} key={0}>
							<Spinner
								size={'30'}
								color={'var(--green)'}
								thickness={3}
								gap={3}
								speed="fast"
							/>
						</div>
					}
				</div>
			</div>
		)
	}
}

export default Occurrences