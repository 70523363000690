import React, { Component } from 'react'
import Calendar from 'react-calendar'
import { CSSTransition } from 'react-transition-group'

import down from '../../assets/images/arrow-down.svg'
import up from '../../assets/images/arrow-up.svg'

import './style.scss'

class CustomCalendar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openCalendar: false
        }

        this.toggleCalendarVisibility = this.toggleCalendarVisibility.bind(this)
    }

    toggleCalendarVisibility() {
        this.setState(prevState => ({
			openCalendar: !prevState.openCalendar
		}))
    }

    render() {
        const {
            buttomLabel,
            activeStartDate,
            onActiveDateChange,
            onChange,
            onClickMonth,
            value,
            tileClassName
        } = this.props

        const {
            openCalendar
        } = this.state

        return (
            <div className={'calendar'}>
                <span
                    className={'calendar__toggle'}
                    onClick={this.toggleCalendarVisibility}
                >
                    {buttomLabel}
                    {openCalendar ?
                        <svg>
                            <use xlinkHref={`${up}#arrow-up`} />
                        </svg>
                        :
                        <svg>
                            <use xlinkHref={`${down}#arrow-down`} />
                        </svg>
                    }
                </span>

                <CSSTransition
                    in={openCalendar}
                    timeout={300}
                    classNames={'calendar__wrapper'}
                    unmountOnExit
                >
                    <div>
                        <Calendar
                            className={'calendar__content'}
                            showNeighboringMonth={false}
                            //minDate={new Date()}
                            activeStartDate={activeStartDate}
                            onActiveDateChange={onActiveDateChange}
                            onChange={onChange}
                            onClickMonth={onClickMonth}
                            value={value}
                            tileClassName={tileClassName}
                        />
                        <hr/>
                    </div>
                </CSSTransition>
            </div>
        )
    }
}

export default CustomCalendar