import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isLogged } from '../utils';

class PrivateRoute extends Component {
	constructor(props){
		super(props)

		this.state = {
			ready: false,
			logged: false
		}
	}

	async componentDidMount() {
		let logged = await isLogged()

		this.setState({ logged: logged, ready: true })
	}

	render() {
		const { ready, logged } = this.state
		
		const route = (
			<Route {...this.props} />
		)

		const redirect = (
			<Redirect to={'/'} />
		)

		if(!ready) {
			return null
        }

		return logged ? route : redirect
	} 
}

export default PrivateRoute