import React from 'react'
import { Link } from 'react-router-dom'
import VMasker from 'vanilla-masker'
import ReactGA from 'react-ga'
import * as moment from 'moment'
import 'moment/locale/pt-br'

import './style.scss'

import profile from '../../../assets/images/profile.svg'
import envelope from '../../../assets/images/envelope.svg'
import telephone from '../../../assets/images/telephone.svg'
import clock from '../../../assets/images/clock.svg'
import whatsapp from '../../../assets/images/whatsapp.svg'
import details from '../../../assets/images/details.svg'
import register from '../../../assets/images/register.svg'
import taskDone from '../../../assets/images/task-done.svg'
import calendar from '../../../assets/images/calendar.svg'
import contact from '../../../assets/images/contact.svg'

const TasksListItem = (props) => {
    const {
        type,
        item
    } = props

    let statusModifier = null
    let statusText = null

    switch(item.status) {
        case 'A':
            statusModifier = 'task__status--warning'
            statusText = 'Negociação'
            break
        case 'D':
            statusModifier = 'task__status--danger'
            statusText = 'Dependente'
            break
        case 'E':
            statusModifier = ''
            statusText = 'Encerrado'
            break
        case 'F':
            statusModifier = 'task__status--success'
            statusText = 'Fechado'
            break
        case 'N':
            statusModifier = 'task__status--success'
            statusText = 'Entregue'
            break
        case 'P':
            statusModifier = 'task__status--warning'
            statusText = 'Aberto'
            break
        case 'S':
            statusModifier = ''
            statusText = 'Descartado'
            break
        case 'I':
            statusModifier = ''
            statusText = 'Importunidade'
            break
        default:
            statusModifier = ''
    }

    let showTaskInfo = item.attendanceStatus === 'A' || (item.contactDate && moment(moment.utc(item.contactDate).format('YYYY-MM-DD HH:mm')) > moment())

    return (
        <li className={`task ${!item.contactDate && 'task--warning'} ${type === 'overdue' && 'task--danger'} ${showTaskInfo && item.attendanceStatus === 'C' && 'task--complete'}`}>
            <div className={'task__content-container'}>
                <p className={`task__status ${statusModifier}`}>
                    {statusText}
                </p>
                <p className={'task__sub-status'}>
                    {showTaskInfo && item.service}
                </p>
                <p className={'task__id'}>
                    {`Pedido #${item.requestId}`}
                </p>
                {showTaskInfo &&
                    <div className={'task__contact'}>
                        <hr/>
                        <p className={'task__contact__text'}>
                            <svg>
                                <use xlinkHref={`${contact}#contact`} />
                            </svg>
                            <span>{item.contactText}</span>
                        </p>
                        <p className={'task__contact__time'}>
                            <svg>
                                <use xlinkHref={`${calendar}#calendar`} />
                            </svg>
                            <span>{moment(item.contactDate).format('DD/MM/YYYY')}</span>
                            <svg>
                                <use xlinkHref={`${clock}#clock`} />
                            </svg>
                            <span>{item.contactHour}</span>
                        </p>
                        {item.attendanceStatus === 'C' &&
                            <p className={'task__contact__complete-time'}>
                                <svg>
                                    <use xlinkHref={`${taskDone}#task_done`} />
                                </svg>
                                {`
                                    Concluída em: 
                                    ${moment.utc(item.attendanceDate).format('DD/MM/YYYY')}
                                    às
                                    ${moment.utc(item.attendanceDate).format('HH:mm')}
                                `}
                            </p>
                        }
                    </div>
                }
                <hr/>
                <p className={'task__client-name'}>
                    <svg>
                        <use xlinkHref={`${profile}#profile`} />
                    </svg>
                    {item.client.name}
                </p>
                <p className={'task__client-email'}>
                    <svg>
                        <use xlinkHref={`${envelope}#envelope`} />
                    </svg>
                    <a
                        href={`mailto:${item.client.email}`}
                        onClick={() =>
                            ReactGA.event({
                                category: 'Home',
                                action: 'Clique',
                                label: 'Home_Email'
                            })
                        }

                    >
                        {item.client.email}
                    </a>
                </p>
                <p className={'task__client-phone'}>
                    <svg>
                        <use xlinkHref={`${telephone}#telephone`} />
                    </svg>
                    <a
                        href={`tel:${item.client.phone}`}
                        onClick={() =>
                            ReactGA.event({
                                category: 'Home',
                                action: 'Clique',
                                label: 'Home_Cel'
                            })
                        }
                    >
                        { item.client.phone && (item.client.phone.length === 11 ? VMasker.toPattern(item.client.phone, "(99) 99999-9999") : VMasker.toPattern(item.client.phone, "(99) 9999-9999")) }
                    </a>
                    <span>-</span>
                    <svg>
                        <use xlinkHref={`${whatsapp}#whatsapp`} />
                    </svg>
                    <a
                        href={`https://api.whatsapp.com/send?phone=${'55' + item.client.phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                            ReactGA.event({
                                category: 'Home',
                                action: 'Clique',
                                label: 'Home_Whats'
                            })
                        }
                    >
                        {'WhatsApp'}
                    </a>
                </p>
                <hr/>
                <div className={'task__links'}>
                    <Link
                        to={{
                           pathname: `/requests/${item.id}`,
                           state: {
                               page: 'general',
                               formType: null
                           }
                        }}
                        className={'task__links__link'}
                        onClick={() =>
                            ReactGA.event({
                                category: 'Home',
                                action: 'Clique',
                                label: 'Home_Detalhes'
                            })
                        }
                    >
                        <svg>
                            <use xlinkHref={`${details}#details`} />
                        </svg>
                        {'Detalhes'}
                    </Link>
                    <Link
                        to={{
                           pathname: `/requests/${item.id}`,
                           state: {
                               page: 'service',
                               formType: 'registry'
                           }
                        }}
                        className={'task__links__link'}
                        onClick={() =>
                            ReactGA.event({
                                category: 'Home',
                                action: 'Clique',
                                label: 'Home_Detalhes'
                            })
                        }
                    >
                        <svg>
                            <use xlinkHref={`${register}#register`} />
                        </svg>
                        {'Atendimento'}
                    </Link>
                    {item.attendanceStatus === 'A' &&
                        <Link
                            to={{
                            pathname: `/requests/${item.id}`,
                            state: {
                                page: 'service',
                                formType: 'task'
                            }
                            }}
                            className={'task__links__link'}
                            onClick={() =>
                                ReactGA.event({
                                    category: 'Home',
                                    action: 'Clique',
                                    label: 'Home_Detalhes'
                                })
                            }
                        >
                            <svg>
                                <use xlinkHref={`${taskDone}#task_done`} />
                            </svg>
                            {'Concluir'}
                        </Link>
                    }
                </div>
            </div>
        </li>
    )
}

export default TasksListItem