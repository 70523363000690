class Auth {
	constructor(API_URL, API_KEY) {
		this.API_URL = API_URL
		this.API_KEY = API_KEY
	}

	async login(email, password) {
        try {
            let request = await fetch(`${this.API_URL}/auth/login`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }

    async logout(token) {
        try {
            let request = await fetch(`${this.API_URL}/auth/logout`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': token
                }
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }

    async check(token) {
        try {
            let request = await fetch(`${this.API_URL}/auth/check`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-key': this.API_KEY,
                    'x-access-token': token
                }
            })

            return await request.json()
        } catch (error) {
            return error
        }
    }
}

export default Auth