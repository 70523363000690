import React from 'react'

import './style.scss'
import ListItem from './OccurrencesListItem'

const OccurrencesList = (props) => {
    const {
        occurrences
    } = props

    return (
        <div className={'occurrences-list'}>
            <h3 className={'occurrences-list__title'}>Ocorrências anteriores</h3>
			<hr/>
            {occurrences && occurrences.length > 0 ?
                <ul>
                    {occurrences.map((occurrence, index) =>
                        <ListItem
                            key={index.toString()}
                            item={occurrence}
                        />
                    )}
                </ul>
                :
                <p className={'occurrences-list__no-occurrence-text'}>{occurrences && occurrences.length === 0 && 'Nenhuma nova ocorrência.'}</p>
            }
        </div>
    )
}

export default OccurrencesList