import React, { Component } from 'react'
import Spinner from "react-svg-spinner"
import * as moment from 'moment'
import 'moment/locale/pt-br'
import ReactGA from 'react-ga'

import Header from '../../components/Header'
import Navbar from '../../components/Navbar'

import './style.scss'

import Api from '../../services/Api'

import SelectFilter from '../../components/SelectFilter'
import TasksList from '../../components/TasksList'

class Tasks extends Component {
	constructor(props) {
        super(props)

        this.state = {
			user: window.user,
			tasks: [],
			tasksFilter: 'unrealized',
			today: moment().format('YYYY-MM-DD'),
			loading: true
		}

		this.setFilter = this.setFilter.bind(this)
	}

	controller = new AbortController()

	async componentDidMount() {
		this.getUnrealized()
		
		ReactGA.pageview(window.location.pathname + window.location.search, null, 'Home')
	}

	componentWillUnmount() {
		this.controller.abort()
	}

	async getUnrealized() {
		this.setState({loading: true})

		let response = await Api.Tasks.unrealized(this.state.today, this.controller.signal)

		this.setState({tasks: response.data})
		this.setState({loading: false})
	}

	async getOverdue() {
		this.setState({loading: true})

		let response = await Api.Tasks.overdue(this.state.today, this.controller.signal)

		this.setState({tasks: response.data})
		this.setState({loading: false})
	}

	async getRealized() {
		this.setState({loading: true})

		let response = await Api.Tasks.realized(this.state.today, this.controller.signal)

		this.setState({tasks: response.data})
		this.setState({loading: false})
	}

	setFilter(name, value) {
		this.setState({tasksFilter: value})
		
		if (value === 'unrealized') {
			this.getUnrealized()
			ReactGA.event({
				category: 'Home',
				action: 'Clique',
				label: 'Home_Nao_Realizadas'
			})
		} else if (value === 'overdue') {
			this.getOverdue()
			ReactGA.event({
				category: 'Home',
				action: 'Clique',
				label: 'Home_Vencidas'
			})
		} else if (value === 'realized') {
			this.getRealized()
			ReactGA.event({
				category: 'Home',
				action: 'Clique',
				label: 'Home_Realizadas'
			})
		}
	}

	render() {
		const {
			user,
			tasks,
			tasksFilter,
			loading
		} = this.state

		return (
			<div className={'tasks page'}>
				<Header title={'Tarefas'} />
				<div className={'tasks__content-container'}>
					<p className={'tasks__welcome-text'}>Olá <span>{ user.name }</span></p>
					<p className={'tasks__text'}>essas são as suas tarefas agendadas:</p>

					{!loading &&
						<SelectFilter
							id={'tasksFilter'}
							name={'tasksFilter'}
							label={'Visualizar tarefas'}
							selected={tasksFilter}
							options={[
								{title: 'Não realizadas', value: 'unrealized'},
								{title: 'Vencidas', value: 'overdue'},
								{title: 'Realizadas', value: 'realized'}
							]}
							onChange={this.setFilter}
						/>
					}

					{!loading && tasks.length > 0 &&
						<TasksList
							type={tasksFilter}
							tasksGroups={tasks}
						/>
					}

					{!loading && tasksFilter === 'unrealized' && tasks.length === 0 &&
						<div>
							<hr />
							<p className={'tasks__text'}>Você não possui tarefas não realizadas no momento.</p>
						</div>
					}

					{!loading && tasksFilter === 'overdue' && tasks.length === 0 &&
						<div>
							<hr />
							<p className={'tasks__welcome-text'}><span>Parabens!</span> </p>
							<p className={'tasks__text'}>Você não possui tarefas vencidas.</p>
						</div>
					}

					{!loading && tasksFilter === 'realized' && tasks.length === 0 &&
						<div>
							<hr />
							<p className={'tasks__text'}>Você não possui tarefas realizadas no momento.</p>
						</div>
					}

					{loading &&
						<div className={'spinner-container'} key={0}>
							<Spinner
								size={'30'}
								color={'var(--green)'}
								thickness={3}
								gap={3}
								speed="fast"
							/>
						</div>
					}
				</div>
				<Navbar current={'tasks'} />
			</div>
		)
	}
}

export default Tasks