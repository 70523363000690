import React from 'react'

import './style.scss'

const Select = (props) => {
    const {
        id,
        name,
        label,
        options,
        selected,
        invalid,
        invalidMessage,
        containerStyle,
        multiple,
        disabled
    } = props

    const handleInputChange = (event) => {
        const target = event.target
        let value = null
        if (multiple) {
            const options = target.options
            value = [...options].filter(o => o.selected).map(o => o.value)
        } else {
            value = target.value
        }
        const name = target.name
        
        props.onChange(name, value)
    }

    const required = props.required ? {'required': true} : null

    return (
        <div
            className={'select-content' + (invalid === true ? ' select-content--invalid' : '') + (disabled === true ? ' select-content--disabled' : '')}
            style={containerStyle}
        >
            {label && 
                <label
                    className={'select-content__label'}
                    htmlFor={id}
                >
                    {label}
                </label>
            }
            <div className={'select-content__wrapper'}>
                <select
                    className={'select-content__select'}
                    id={id}
                    name={name}
                    value={selected}
                    onChange={handleInputChange}
                    multiple={multiple}
                    disabled={disabled}
                    {...required}
                >
                    {options.map((option, index) =>
                        <option
                            key={index.toString()}
                            value={option.value}
                            title={option.title}
                        >
                            {option.title}
                        </option>
                    )}
                </select>
            </div>
            {invalid &&
                <span className={'select-content__error-message'}>
                    {invalidMessage}
                </span>
            }
        </div>
    )
}

export default Select