import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import tasks from '../../assets/images/tasks.svg'
import schedule from '../../assets/images/schedule.svg'
import requests from '../../assets/images/requests.svg'
//import person from '../../assets/images/person.svg'
import options from '../../assets/images/options.svg'

class Navbar extends Component {
	render() {
		let current = this.props.current

		return (
			<div className={'navbar'}>
				<Link to={'/tasks'} className={'navbar__link ' + (current === 'tasks' && 'navbar__link--selected')}>
					<span className={'navbar__link__icon'}>
						<svg className={'navbar__link__svg'}>
							<use xlinkHref={`${tasks}#tasks`} />
						</svg>
					</span>
					<span className={'navbar__link__text'}>Tarefas</span>
				</Link>

				<Link to={'/schedule'} className={'navbar__link ' + (current === 'schedule' && 'navbar__link--selected')}>
					<span className={'navbar__link__icon'}>
						<svg className={'navbar__link__svg'}>
							<use xlinkHref={`${schedule}#schedule`} />
						</svg>
					</span>
					<span className={'navbar__link__text'}>Agenda</span>
				</Link>

				<Link to={'/requests'} className={'navbar__link ' + (current === 'requests' && 'navbar__link--selected')}>
					<span className={'navbar__link__icon'}>
						<svg className={'navbar__link__svg'}>
							<use xlinkHref={`${requests}#requests`} />
						</svg>
					</span>
					<span className={'navbar__link__text'}>Pedidos</span>
				</Link>

				{/*
				<Link to={'/clients'} className={'navbar__link ' + (current === 'clients' && 'navbar__link--selected')}>
					<span className={'navbar__link__icon'}>
						<svg className={'navbar__link__svg'}>
							<use xlinkHref={`${person}#person`} />
						</svg>
					</span>
					<span className={'navbar__link__text'}>Clientes</span>
				</Link>
				*/}

				<Link to={'/options'} className={'navbar__link ' + (current === 'options' && 'navbar__link--selected')}>
					<span className={'navbar__link__icon'}>
						<svg className={'navbar__link__svg'}>
							<use xlinkHref={`${options}#options`} />
						</svg>
					</span>
					<span className={'navbar__link__text'}>Opções</span>
				</Link>
			</div>
		)
	}
}

export default Navbar