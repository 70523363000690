import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './style.scss'
import Input from '../../components/CustomInput'
import Button from '../../components/CustomButton'

import logo from '../../assets/images/logo-leo.svg'

class Recover extends Component {
	constructor(props) {
        super(props)

        this.state = {
			email: '',
			loading: false
		}

		this.setStateValues = this.setStateValues.bind(this)
		this.recover = this.recover.bind(this)
	}

	setStateValues(name, value) {
		this.setState({
			[name]: value
		})
	}

	recover() {
		this.setState({loading: true})

		console.log('O email é: "' + this.state.email + '"')

		setTimeout(() => this.setState({loading: false}), 3000)
	}

	render() {
		const {
			email,
			loading
		} = this.state

		return (
			<div className={'login full'}>
				<div className={'login__container'}>
					<svg className={'login__logo'}>
						<use xlinkHref={`${logo}#logo`} />
					</svg>
					<h1 className={'login__title'}>Esqueceu sua senha?</h1>
					<p className={'login__text'}>Informe seu email que lhe enviaremos instruções para a recuperação.</p>
					<Input
						id={'email'}
						label={'Email'}
						name={'email'}
						type={'text'}
						placeholder={'Digite o seu email'}
						value={email}
						onChange={this.setStateValues}
						//invalid={}
						//invalidMessage{}
					/>
					<Button
						title={'Recuperar senha'}
						type={'primary'}
						onClick={this.recover}
						loading={loading}
						//disabled={true}
					/>
					<Link to={'/'} className={'login__recover-link'}>Retornar para o login</Link>
				</div>
			</div>
		)
	}
}

export default Recover