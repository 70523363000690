import React from 'react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

import './style.scss'

import calendar from '../../../assets/images/calendar.svg'

const RequestsListItem = (props) => {
    const {
        item
    } = props

    let typeModifier = null
    let typeText = null

    switch(item.type) {
        case 'M':
            typeModifier = 'request__type--own'
            typeText = 'Pedido próprio'
            break
        case 'I':
            typeModifier = 'request__type--indication'
            typeText = 'Indicação LEO'
            break
        default:
            typeModifier = ''
    }

    return (
        <li className={'request'}>
            <Link
                to={`/requests/${item.id}`}
                className={'request__link'}
                onClick={() =>
                    ReactGA.event({
                        category: 'Pedidos',
                        action: 'Clique',
                        label: 'Home_Pedidos_Detalhe'
                    })
                }
            >
                <div className={'request__content-container'}>
                    <p className={`request__type ${typeModifier}`}>
                        {typeText}
                    </p>
                    <p className={'request__id'}>
                        <span>Pedido: </span>
                        {`#${item.requestId}`}
                    </p>
                    <p className={'request__description'}>
                        <span>Descrição: </span>
                        {item.message}
                    </p>
                    <p className={'request__client'}>
                        <span>Cliente: </span>
                        {item.client.name}
                    </p>
                    <p className={'request__current-task'}>
                        <span>Tarefa: </span>
                        {`Atendimento ${item.client.name ? item.client.name.split(' ')[0] : ''}`}
                    </p>
                    <p className={'request__next-task'}>
                        <svg>
                            <use xlinkHref={`${calendar}#calendar`} />
                        </svg>
                        <span>Próxima tarefa: </span>
                        {item.contactDate && new Date(item.contactDate).toLocaleDateString('pt-br')}
                    </p>
                </div>
            </Link>
        </li>
    )
}

export default RequestsListItem