import React from 'react'
import * as moment from 'moment'
import 'moment/locale/pt-br'

import './style.scss'

const ServicesListItem = (props) => {
    const {
        item
    } = props

    return (
        <li className={'service-item'}>
            <div className={'service-item__content'}>
                <p className={'service-item__content__title'}>
                    <span>{item.title}</span>
                </p>
                <p className={'service-item__content__date-time'}>
                    <span>{`Data: `}</span>
                    {moment(item.contactDate).format('DD/MM/YYYY')}
                    {` - `}
                    <span>{`Hora: `}</span>
                    {item.contactHour}
                </p>
                <p className={'service-item__content__description'}>
                    {item.description}
                </p>
            </div>
        </li>
    )
}

export default ServicesListItem