import React from 'react'

import './style.scss'
import ListItem from './RequestsListItem'

const RequestsList = (props) => {
    const {
        requests
    } = props

    return (
        <div className={'requests-content'}>
            {requests && requests.length > 0 ?
                <ul>
                    {requests.map((request, index) =>
                        <ListItem
                            key={index.toString()}
                            item={request}
                        />
                    )}
                </ul>
                :
                <p>{requests && requests.length === 0 && 'Nenhum pedido'}</p>
            }
        </div>
    )
}

export default RequestsList