import React from 'react'
import * as moment from 'moment'
import 'moment/locale/pt-br'

import './style.scss'
import ListItem from './TasksListItem'

import calendar from '../../assets/images/calendar.svg'

const TasksList = (props) => {
    const {
        type,
        tasksGroups
    } = props

    let today = moment().format('DD/MM/YYYY')

    moment.locale('pt-BR')

    return (
        <div className={'tasks-content'}>
            {tasksGroups.map((group, index) =>
                <div className={'tasks-content__group'} key={index.toString()}>
                    <p className={'tasks-content__group__info'}>
                        {`${today === group.date ? 'Hoje' : moment(group.date, 'DD/MM/YYYY').format('dddd')} (${group.tasks.length})`}
                        <span>
                            <svg>
                                <use xlinkHref={`${calendar}#calendar`} />
                            </svg>
                            {group.date}
                        </span>
                    </p>
                    <ul>
                        {group.tasks.map((task, index) =>
                            <ListItem
                                type={type}
                                key={index.toString()}
                                item={task}
                            />
                        )}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default TasksList